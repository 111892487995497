import axios from 'axios';
import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import Cafee from './cafee';
import Dashboard from './dashboard';
import GuestRoom from './guestroom';
import Guests from './guests';
import Reception from './reception';
import Room from './room';
import Service from './service';
import Staff from './staff';
import Users from './users';
import moment from 'moment'
import 'moment/locale/ar'
import Login from './login';
import Notification from './notification'
import $ from 'jquery'
import Spending from './spending';
import Report_Cafe from './report_cafe';
import Report_Rervice from './report_service';
import Report_Room from './report_room';
import Report_cleaning from './report_cleaning.jsx';
import Cleaning from './cleaning';
import Modify_guest from './modify-guest';
import Report_Cafx from './repot_cafex';




class Main extends Component {
    state = {
        Notfiy: [],
        CheckNote: [],
        Menu: []
    }
    componentDidMount() {
        axios.post('/readuser').then((ok) => {
            if (ok.data != 'err') {
                const menu = []
                if (ok.data != 'Admin') {
                    menu.push(
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">القائمة</li>
                            <li>
                                <Link to="/dashboard" className="waves-effect" >
                                    <i className="ri-dashboard-fill" />
                                    <span>الرئيسية</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/reception" className="waves-effect" >
                                    <i className="ri-customer-service-2-line" />
                                    <span>استقبال</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/guests" className="waves-effect" >
                                    <i className="fas fa-users"></i>
                                    <span>قائمة الضيوف</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/cafee" className=" waves-effect">
                                    <i className="ri-restaurant-line" />
                                    <span>كافتریا</span>
                                </Link>
                            </li>


                            <li>
                                <Link to="/spending" className=" waves-effect">
                                    <i className="ri-hand-coin-line" />
                                    <span>مصاریف</span>
                                </Link>
                            </li>
                            <li id="reportx" className="x" onClick={() => { this.expand() }}>
                                <a href="javascript: void(0);" className="has-arrow waves-effect" aria-expanded="true" >
                                    <i className="ri-file-chart-line" />
                                    <span>تقاریر</span>
                                </a>
                                <ul className="sub-menu mm-collapse" aria-expanded="true">
                                    <li><Link to="/report-room" className=" waves-effect">
                                        <i className="ri-subtract-line" />
                                        <span>تقاریر الغرف</span>
                                    </Link></li>
                                </ul>
                            </li>
                        </ul>
                    )
                } else {
                    menu.push(
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">القائمة</li>
                            <li>
                                <Link to="/dashboard" className="waves-effect" >
                                    <i className="ri-dashboard-fill" />
                                    <span>الرئيسية</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/reception" className="waves-effect" >
                                    <i className="ri-customer-service-2-line" />
                                    <span>استقبال</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/guests" className="waves-effect" >
                                    <i className="fas fa-users"></i>
                                    <span>قائمة الضيوف</span>
                                </Link>
                            </li>


                            <li>
                                <Link to="/room" className=" waves-effect">
                                    <i className="ri-hotel-bed-line" />
                                    <span>إدارة الغرف</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/service" className=" waves-effect">
                                    <i className="ri-truck-line" />
                                    <span>خدمات</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/cafee" className=" waves-effect">
                                    <i className="ri-restaurant-line" />
                                    <span>كافتریا</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/cleaning" className=" waves-effect">
                                    <i className="ri-paint-brush-line" />
                                    <span>مواد التنظيفات</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/staff" className=" waves-effect">
                                    <i className="ri-user-star-line" />
                                    <span>موظفين</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/spending" className=" waves-effect">
                                    <i className="ri-hand-coin-line" />
                                    <span>مصاریف</span>
                                </Link>
                            </li>
                            <li id="reportx" className="x" onClick={() => { this.expand() }}>
                                <a href="javascript: void(0);" className="has-arrow waves-effect" aria-expanded="true" >
                                    <i className="ri-file-chart-line" />
                                    <span>تقاریر</span>
                                </a>
                                <ul className="sub-menu mm-collapse" aria-expanded="true">
                                    <li><Link to="/report-cafe" className=" waves-effect">
                                        <i className="ri-subtract-line" />
                                        <span>تقاریر الکافی</span>
                                    </Link></li>
                                    <li><Link to="/report-cafex" className=" waves-effect">
                                        <i className="ri-subtract-line" />
                                        <span>تقاریر الکافي (الخارجیة)</span>
                                    </Link></li>
                                    <li><Link to="/report-cleaning" className=" waves-effect">
                                        <i className="ri-subtract-line" />
                                        <span>تقاریر مواد التنظيف</span>
                                    </Link></li>
                                    <li><Link to="/report-service" className=" waves-effect">
                                        <i className="ri-subtract-line" />
                                        <span>تقاریر الخدمات</span>
                                    </Link></li>
                                    <li><Link to="/report-room" className=" waves-effect">
                                        <i className="ri-subtract-line" />
                                        <span>تقاریر الغرف</span>
                                    </Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/users" className=" waves-effect">
                                    <i className="ri-user-settings-line" />
                                    <span>إدارةالمستخدم</span>
                                </Link>
                            </li>
                        </ul>
                    )
                }
                this.setState({Menu:menu})
                this._notify();
            } else {
                window.toastr['error']('طلب الاذن مرفوض !')
                this.props.history.push('/login')
            }
        })
    }

    logout = () => {
        axios.post('/logout').then((ok) => {
            if (ok.data != 'err') {
                window.location.reload()
            } else {
                window.location.reload()
            }
        })
    }

    expand = () => {
        if ($('#reportx').hasClass('mm-active')) {
            $('#reportx').removeClass('mm-active')
            $('.sub-menu').removeClass('mm-show')
        } else {
            $('#reportx').addClass('mm-active')
            $('.sub-menu').addClass('mm-show')
        }

    }

    _notify = () => {
        axios.post('/get-notify').then((ok) => {
            if (ok.data.length != 0) {
                moment.locale('ar')
                let notify = []
                for (var i = 0; i < ok.data.length; i++) {
                    if (ok.data[i].expire != '0') {
                        var getRecentDate = moment(ok.data[i].expire).fromNow();
                    } else {
                        var getRecentDate = moment(ok.data[i].created).fromNow();
                    }
                    notify.push(
                        <div className="media">
                            <div className="avatar-xs mr-3">
                                <span className="avatar-title bg-primary rounded-circle font-size-16">
                                    <i className="ri-error-warning-line" />
                                </span>
                            </div>
                            <div className="media-body">
                                <h6 className="mt-0 mb-1">{ok.data[i].item}</h6>
                                <div className="font-size-12 text-muted">
                                    <p className="mb-1">نوع: <span className="badge badge-danger">{ok.data[i].type}</span> </p>
                                    <p className="mb-0"><i className="mdi mdi-clock-outline" /> {getRecentDate}</p>
                                </div>
                            </div>
                        </div>

                    )
                }

                this.setState({ Notfiy: notify, CheckNote: <span class="noti-dot"></span> })
            }
        })
    }

    sidebar = () => {
        if ($('body').hasClass('sidebar-enable vertical-collpsed')) {
            $('body').removeAttr('class')
        } else {
            $($('body').attr('class', 'sidebar-enable vertical-collpsed'))
        }
    }

    render() {
        return (
            <div>
                <div id="layout-wrapper">
                    <header id="page-topbar">
                        <div className="navbar-header">
                            <div className="d-flex">
                                <div className="navbar-brand-box">

                                    <a href="/dashboard" className="logo logo-dark">
                                        <span className="logo-sm">
                                            <img src="assets/images/logo.png" alt="" height={22} />
                                        </span>
                                        <span className="logo-lg">
                                            <img src="assets/images/logo.png" alt="" height={20} />
                                        </span>
                                    </a>
                                    <a href="/dashboard" className="logo logo-light">
                                        <span className="logo-sm">
                                            <img src="assets/images/logo.png" alt="" height={22} />
                                        </span>
                                        <span className="logo-lg">
                                            <img src="assets/images/logo.png" alt="" height={20} />
                                        </span>
                                    </a>
                                </div>

                                <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn" onClick={() => { this.sidebar() }}>
                                    <i className="ri-menu-2-line align-middle" />
                                </button>
                            </div>
                            <div className="d-flex">

                                <div className="dropdown d-inline-block">
                                    <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="ri-notification-3-line" />
                                        {this.state.CheckNote}
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-notifications-dropdown">
                                        <div className="p-3">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h6 className="m-0"> تنبيهات </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-simplebar style={{ maxHeight: '400px' }}>
                                            <a href className="text-reset notification-item">
                                                {this.state.Notfiy}
                                            </a>
                                        </div>
                                        <div className="p-2 border-top">
                                            <Link className="btn btn-sm btn-link font-size-14 btn-block text-center" to="/notification">
                                                عرض المزيد <i className="mdi mdi-arrow-left-circle mr-1" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="dropdown d-inline-block user-dropdown">
                                    <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="ri-user-line rounded-circle header-profile-user"></i>

                                        <span className="d-none d-xl-inline-block ml-1">المستخدم</span>
                                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <Link to="/users" className="dropdown-item d-block"><i className="ri-settings-2-line align-middle mr-1" /> اعدادات</Link>
                                        <div className="dropdown-divider" />
                                        <a className="dropdown-item text-danger" href="javascript: void(0);" onClick={() => { this.logout() }}><i className="ri-shut-down-line align-middle mr-1 text-danger" /> تسجيل خروج</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </header>
                    <div className="vertical-menu">
                        <div data-simplebar className="h-100">
                            <div id="sidebar-menu">
                                {this.state.Menu}
                            </div>
                        </div>
                    </div>
                    <div className="main-content">
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/users" component={Users} />
                        <Route exact path="/staff" component={Staff} />
                        <Route exact path="/guests" component={Guests} />
                        <Route exact path="/room" component={Room} />
                        <Route exact path="/cafee" component={Cafee} />
                        <Route exact path="/cleaning" component={Cleaning} />
                        <Route exact path="/service" component={Service} />
                        <Route exact path="/reception" component={Reception} />
                        <Route exact path="/guest-room" component={GuestRoom} />
                        <Route exact path="/notification" component={Notification} />
                        <Route exact path="/spending" component={Spending} />
                        <Route exact path="/report-cafe" component={Report_Cafe} />
                        <Route exact path="/report-cafex" component={Report_Cafx} />
                        <Route exact path="/report-cleaning" component={Report_cleaning} />
                        <Route exact path="/report-service" component={Report_Rervice} />
                        <Route exact path="/report-room" component={Report_Room} />
                        <Route exact path="/modify-guest" component={Modify_guest} />

                        <Route exact path="/login" component={Login} />
                        <Route exact path="/" component={Login} />

                        <footer className="footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-6">
                                        Lavender Motel <i className="fas fa-copyright" />
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="text-sm-right d-none d-sm-block">
                                            Crafted with <i className="mdi mdi-heart text-danger" /> <a href="https://www.facebook.com/darezhar/" target="_blank">Darezhar</a> <i className="fas fa-code" />  <a href="https://www.facebook.com/uidsameer/" target="_blank">Sam Mohammed</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>

        )
    }
}

export default Main
