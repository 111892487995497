import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'

class Room extends Component {
    state = {
        RoomList: [],
        Beds: [],
        BedsList: [],
        RoomType: [],
        RoomTypeList: [],

    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        axios.post('/readuser').then((ok) => {
            if (ok.data != 'Admin') {
                window.toastr['error']('طلب الاذن مرفوض !')
                this.props.history.push('/dashboard')
            } else {
                this._getRoom();
                this._getBeds();
                this._getRoomType();
            }
        })

    }

    _getBeds = () => {
        axios.post('/get-beds').then((ok) => {
            if (ok.data.length != 0) {
                const beds = [],
                    bedsList = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id
                    beds.push(
                        <option value={ok.data[i].beds}>{ok.data[i].beds}</option>
                    )
                    bedsList.push(
                        <tbody>
                            <tr>
                                <td id="idtbl">#{ok.data[i].id}</td>
                                <td>{ok.data[i].beds}</td>
                                <td>
                                    <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delbed(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>
                                </td>
                            </tr>
                        </tbody>
                    )
                }
                this.setState({ Beds: beds, BedsList: bedsList })
            }
        })
    }

    delbed = (id) => {
        axios.post('/del-bed?id=' + id).then((ok) => {
            if (ok.data != 'err') {
                this._getBeds();
                window.toastr['success']('تم المسح السریر بنجاح')
            } else {
                window.toastr['success']('تم المسح السریر بنجاح')
                setTimeout(() => {
                    window.location.reload();
                }, 400);
            }
        })
    }
    exapndRoomBed = (id) => {
        $(`#editBeds-${id}`).empty();
        axios.post('/get-beds').then((ok) => {
            for (var i = 0; i < ok.data.length; i++) {
                $(`#editBeds-${id}`).append(`<option value=${ok.data[i].beds}>${ok.data[i].beds}</option>`)
            }
        })
    }

    exapndRoomtpye = (id) => {
        this.exapndRoomBed(id);
        $(`#editRoomType-${id}`).empty();
        axios.post('/get-roomType').then((ok) => {
            for (var i = 0; i < ok.data.length; i++) {
                $(`#editRoomType-${id}`).append(`<option value=${ok.data[i].type}>${ok.data[i].type}</option>`)
            }
        })
    }

    _getRoomType = () => {
        axios.post('/get-roomType').then((ok) => {
            if (ok.data.length != 0) {
                const roomType = [],
                    roomTypeList = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id
                    roomType.push(
                        <option value={ok.data[i].type}>{ok.data[i].type}</option>
                    )
                    roomTypeList.push(
                        <tbody>
                            <tr>
                                <td id="idtbl">#{ok.data[i].id}</td>
                                <td>{ok.data[i].type}</td>
                                <td>
                                    <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delroomType(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>
                                </td>
                            </tr>
                        </tbody>
                    )
                }
                this.setState({ RoomType: roomType, RoomTypeList: roomTypeList })
            }
        })
    }

    delroomType = (id) => {
        axios.post('/del-roomType?id=' + id).then((ok) => {
            if (ok.data != 'err') {
                this._getRoom();
                window.toastr['success']('تم المسح نوع الغرفة بنجاح')
            } else {
                window.toastr['success']('تم المسح نوع الغرفة  بنجاح')
                setTimeout(() => {
                    window.location.reload();
                }, 400);
            }
        })
    }

    _getRoom = () => {
        axios.post('/get-room').then((ok) => {
            if (ok.data.length != 0) {
                let room = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id,
                        modId = `Room_${id}`
                    if (ok.data[i].isValid != 0) {
                        var setStyle = "badge badge-soft-danger font-size-12"
                    } else {
                        var setStyle = "badge badge-soft-success font-size-12"
                    }
                    room.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td>{ok.data[i].roomNo}</td>
                            <td>{ok.data[i].roomType}</td>
                            <td>{ok.data[i].beds}</td>
                            <td>{ok.data[i].price}</td>
                            <td>{ok.data[i].price_by}</td>
                            <td><div className={setStyle}>{ok.data[i].isValid}</div></td>
                            <td>{new Date(ok.data[i].created).toLocaleString().toString()} </td>
                            <td>
                                <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="modal" data-target={`#${modId}`} onClick={() => { this.exapndRoomtpye(id) }} ><i className="mdi mdi-pencil font-size-18" /></a>
                                <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delRoom(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>

                                <div className="modal fade" id={modId} tabIndex={-1} role="dialog" aria-labelledby="newRoom" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newRoom">تحریر الغرفة  </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="roomNo">اسم الغرفة</label>
                                                    <input type="text" className="form-control" id={`edit-roomNo-${id}`} defaultValue={ok.data[i].roomNo} required />
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-4 col-form-label">نوع الغرف</label>
                                                    <div className="col-md-8">
                                                        <select className="form-control" id={`editRoomType-${id}`}>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-4 col-form-label">السرير</label>
                                                    <div className="col-md-8">
                                                        <select className="form-control" id={`editBeds-${id}`}>
                                                        </select>

                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price">سعر لكل واحد</label>
                                                    <input type="number" className="form-control" id={`edit-price-by-${id}`} defaultValue={ok.data[i].price_by} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price">سعر اجمالی</label>
                                                    <input type="number" className="form-control" id={`edit-price-${id}`} defaultValue={ok.data[i].price} required />
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.modify(id) }} id="submit" className="btn btn-primary" data-dismiss='modal' >تحدیث</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </td>
                        </tr>
                    )
                }
                this.setState({ RoomList: room })
            }
        })
    }

    AddRoom = () => {
        const roomNo = $('#roomNo').val(),
            beds = $('#beds').val(),
            roomType = $('#roomType').val(),
            price = $('#price').val(),
            priceby = $('#priceby').val()

        if (roomNo.length != 0 && price.length != 0) {
            axios.post('/add-room', { roomNo, beds, roomType, price, priceby }).then((ok) => {
                if (ok.data != 'err') {
                    this._getRoom();
                    window.toastr['success']('تم تسجيل غرفة جديدة بنجاح')
                } else {
                    window.toastr['error']('هذا الغرفة مسجل بالفعل')
                }
            })
        } else {
            window.toastr['error']('من فضلك املأ الاستمارة')
        }
    }


    delRoom = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، امسح الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/del-room?id=' + id).then((ok) => {
                    if (ok.data != 'err') {
                        this._getRoom();
                        window.toastr['success']('تم المسح الغرفة بنجاح')
                    } else {
                        window.toastr['success']('تم المسح الغرفة بنجاح')
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                    }
                })
            }
        })
    }

    modify = (id) => {
        const roomNo = $(`#edit-roomNo-${id}`).val(),
            beds = $(`#editBeds-${id}`).val(),
            roomType = $(`#editRoomType-${id}`).val(),
            price = $(`#edit-price-${id}`).val(),
            priceby = $(`#edit-price-by-${id}`).val()

        axios.post(`/modify-room?id=${id}`, { roomNo, beds, roomType, price, priceby }).then((ok) => {
            if (ok.data != 'err') {
                window.toastr['success']('تم التحديث معلومات الغرفة بنجاح')
                this._getRoom();
            } else {
                window.toastr['error']('حدث خطأ ما. أعد المحاولة من فضلك')
            }
        })
    }

    AddRoomType = () => {
        const type = $('#addroomtype').val();
        if (type.length != 0) {
            axios.post('/new-roomType', { type }).then((ok) => {
                if (ok.data = 'done') {
                    this._getRoomType();
                    window.toastr['success']('تم تسجيل نوع الغرفة بنجاح')
                } else {
                    window.toastr['error']('هذا النوع الغرفة مسجل بالفعل')
                }
            })
        } else {
            window.toastr['error']('من فضلك املأ الاستمارة')
        }
    }

    addNewBed = () => {
        const beds = $('#addnewbed').val();
        if (beds.length != 0) {
            axios.post('/new-bed', { beds }).then((ok) => {
                if (ok.data = 'done') {
                    this._getBeds();
                    window.toastr['success']('تم تسجيل عدد الغرفة بنجاح')
                } else {
                    window.toastr['error']('هذا عدد الغرفة مسجل بالفعل')
                }
            })
        } else {
            window.toastr['error']('من فضلك املأ الاستمارة')
        }
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <button type="button" className="mb-0 btn btn-primary" data-toggle="modal" data-target="#addroom"> اضافة غرفة جدیدة </button>


                                <div className="modal fade" id='addroom' tabIndex={-1} role="dialog" aria-labelledby="addroom" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newRoom">اضافة الغرفة جدیدة </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="roomNo">اسم الغرفة</label>
                                                    <input type="text" className="form-control" id="roomNo" placeholder="01" required />
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-3 col-form-label">نوع الغرفة</label>
                                                    <div className="col-md-9">
                                                        <select className="form-control" id="roomType">
                                                            {this.state.RoomType}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-3 col-form-label">السرير</label>
                                                    <div className="col-md-9">
                                                        <select className="form-control" id="beds">
                                                            {this.state.Beds}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price">سعر لکل واحد</label>
                                                    <input type="number" className="form-control" id="priceby" placeholder="0" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="price">سعر اجمالی</label>
                                                    <input type="number" className="form-control" id="price" placeholder="0" required />
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.AddRoom() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافە</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">ادارة الغرف</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>اسم الغرفة</th>
                                                    <th>نوع الغرفة</th>
                                                    <th>السریر</th>
                                                    <th>سعر الاجمالی</th>
                                                    <th>سعر لکل واحد</th>
                                                    <th>حالة</th>
                                                    <th>تاریخ التسجیل</th>
                                                    <th style={{ width: '120px' }}>الخیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">
                                                {this.state.RoomList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6">
                            <button type="button" className="mb-0 btn btn-warning" data-toggle="modal" data-target="#addxbeds">اضافة السریر </button>
                            <br /><br />
                            <div className="modal fade" id='addxbeds' tabIndex={-1} role="dialog" aria-labelledby="addxroomtype" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="newRoom">اضافة السریر </h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label htmlFor="addbedtype">عدد السریر</label>
                                                <input type="number" className="form-control" id="addnewbed" placeholder="01" required />
                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                            <button type="button" onClick={() => { this.addNewBed() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافة</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>عدد السریر</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            {this.state.BedsList}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <button type="button" className="mb-0 btn btn-success" data-toggle="modal" data-target="#addxroomtype">  اضافة نوع الغرفة</button>
                            <br /><br />
                            <div className="modal fade" id='addxroomtype' tabIndex={-1} role="dialog" aria-labelledby="addxroomtype" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="newRoom">اضافة نوع الغرفة </h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label htmlFor="addroomtype">نوع الغرفة</label>
                                                <input type="text" className="form-control" id="addroomtype" placeholder="VIP" required />
                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                            <button type="button" onClick={() => { this.AddRoomType() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافة</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>نوع الغرفة</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            {this.state.RoomTypeList}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Room
