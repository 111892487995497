import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'
class Staff extends Component {
    state = {
        StaffList: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        axios.post('/readuser').then((ok) => {
            if (ok.data != 'Admin') {
                window.toastr['error']('طلب الاذن مرفوض !')
                this.props.history.push('/dashboard')
            } else {
                this._getStaffs();
            }
        })
    }



    _getStaffs = () => {
        axios.post('/get-staff').then((ok) => {
            if (ok.data.length != 0) {
                let staff = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id,
                        modId = `Staff_${id}`
                    staff.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td>{ok.data[i].name}</td>
                            <td>{ok.data[i].phone}</td>
                            <td>{ok.data[i].type}</td>
                            <td><div className='badge badge-soft-success font-size-12'>{ok.data[i].shift}</div></td>
                            <td>{ok.data[i].salary} IQD</td>
                            <td>
                                <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="modal" data-target={`#${modId}`} ><i className="mdi mdi-pencil font-size-18" /></a>
                                <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delStaff(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>

                                <div className="modal fade" id={modId} tabIndex={-1} role="dialog" aria-labelledby="newStaff" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newStaff">تحریر معلومات الموظف </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="full name">اسم</label>
                                                    <input type="text" className="form-control" id={`edit-fullname-${id}`} defaultValue={ok.data[i].name} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="phone">هاتف</label>
                                                    <input type="number" className="form-control" id={`edit-phone-${id}`} defaultValue={ok.data[i].phone} required />
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-2 col-form-label">نوع</label>
                                                    <div className="col-md-10">
                                                        <select className="form-control" id={`edit-gender-${id}`}>
                                                            <option>ذکر</option>
                                                            <option>انثی</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">نوع الوظیفة</label>
                                                    <input type="text" className="form-control" id={`edit-type-${id}`} defaultValue={ok.data[i].type} required />
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-12 col-form-label" >وقت الدوام</label>
                                                    <div className="col-md-6">
                                                        <input type="text" className="form-control" id={`edit-from-${id}`} placeholder="من ساعە" required />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="text" className="form-control" id={`edit-to-${id}`} placeholder="الی ساعە" required />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="edit-salary">راتب</label>
                                                    <input type="number" className="form-control" id={`edit-salary-${id}`} defaultValue={ok.data[i].salary} required />
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.modify(id) }} id="submit" className="btn btn-primary" data-dismiss='modal' >تحدیث</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    )
                }
                this.setState({ StaffList: staff })
            }
        })
    }

    AddStaff = () => {
        const fullname = $('#fullname').val(),
            phone = $('#phone').val(),
            gender = $('#gender').val(),
            shift = ` من ${$('#from').val()} الی ${$('#to').val()}`,
            type = $('#type').val(),
            salary = $('#salary').val()

        if (fullname.length != 0 && phone.length != 0 && type.length != 0 && salary.length != 0) {
            axios.post('/add-staff', { fullname, phone, gender, shift, type, salary }).then((ok) => {
                if (ok.data = 'done') {
                    this._getStaffs();
                    window.toastr['success']('تم تسجيل موظف جديد بنجاح')
                } else {
                    window.toastr['error']('هذا الموظف مسجل بالفعل')
                }
            })
        } else {
            window.toastr['error']('من فضلك املأ الاستمارة')
        }
    }


    delStaff = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، امسح الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/del-staff?id=' + id).then((ok) => {
                    if (ok.data != 'err') {
                        this._getStaffs();
                        window.toastr['success']('تم المسح الموظف بنجاح')
                    } else {
                        window.toastr['success']('تم المسح الموظف بنجاح')
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                    }
                })
            }
        })
    }



    modify = (id) => {
        const fullname = $(`#edit-fullname-${id}`).val(),
            phone = $(`#edit-phone-${id}`).val(),
            gender = $(`#edit-gender-${id}`).val(),
            shift = ` من ${$(`#edit-from-${id}`).val()} الی ${$(`#edit-to-${id}`).val()}`,
            type = $(`#edit-type-${id}`).val(),
            salary = $(`#edit-salary-${id}`).val()

        axios.post(`/modify-staff?id=${id}`, { fullname, phone, gender, shift, type, salary }).then((ok) => {
            if (ok.data != 'err') {
                window.toastr['success']('تم التحديث معلومات الموظف بنجاح')
                this._getStaffs();
            } else {
                window.toastr['error']('حدث خطأ ما. أعد المحاولة من فضلك')
            }
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <button type="button" className="mb-0 btn btn-primary" data-toggle="modal" data-target="#addstaff">اضافة الموظف الجدید </button>

                                <div className="modal fade" id='addstaff' tabIndex={-1} role="dialog" aria-labelledby="addstaff" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newStaff">اضافة الموظف الجدید </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="full name">اسم</label>
                                                    <input type="text" className="form-control" id="fullname" placeholder="اسم ..." required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="phone">هاتف</label>
                                                    <input type="number" className="form-control" id="phone" placeholder="07 ..." required />
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-2 col-form-label">نوع</label>
                                                    <div className="col-md-10">
                                                        <select className="form-control" id="gender">
                                                            <option>ذکر</option>
                                                            <option>انثی</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-12 col-form-label" >وقت الدوام</label>
                                                    <div className="col-md-6">
                                                        <input type="text" className="form-control" id="from" placeholder="من ساعە" required />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="text" className="form-control" id="to" placeholder="الی ساعە" required />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">نوع الوظیفة</label>
                                                    <input type="text" className="form-control" id="type" placeholder="طباخ ..." required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="salary">راتب</label>
                                                    <input type="number" className="form-control" id="salary" placeholder="00.00 IQD" required />
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.AddStaff() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافة</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">ادارة الموظفین</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>اسم</th>
                                                    <th>هاتف</th>
                                                    <th>الوظیفة</th>
                                                    <th>وقت الدوام</th>
                                                    <th>راتب</th>
                                                    <th>خیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">
                                                {this.state.StaffList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Staff
