import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'

class Report_Rervice extends Component {

    state = {
        ServiceList: [],
        Coat: [],
        MonthCost: [],
        MonthServiceList: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        $('.bymonth').hide();
        axios.post('/readuser').then((ok) => {
            if (ok.data != 'Admin') {
                window.toastr['error']('طلب الاذن مرفوض !')
                this.props.history.push('/dashboard')
            } else {
                this._getReport_Service();
                this._serviceInvo();
            }
        })

    }

    delReport = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، امسح الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/del-service-report?id=' + id).then((ok) => {
                    if (ok.data != 'err') {
                        this._getReport_Service();
                        this._serviceInvo();
                        window.toastr['success']('تم المسح بنجاح')
                    } else {
                        window.toastr['success']('تم المسح بنجاح')
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                    }
                })
            }
        })
    }

    _getReport_Service = () => {
        axios.post('/get-report-service').then((ok) => {
            if (ok.data.length != 0) {
                let service = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id

                    service.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td>{ok.data[i].item_name}</td>
                            <td>{ok.data[i].cost}</td>
                            <td style={{ direction: 'ltr' }}>{new Date(ok.data[i].created).toLocaleString().toString()} </td>
                            <td>
                                <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delReport(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>
                            </td>
                        </tr>
                    )
                }
                this.setState({ ServiceList: service })
            }
        })
    }

    _serviceInvo = () => {
        axios.post('/service-invo-cost').then((ok) => {
            const cost = []
            if (ok.data.totalDay) {
                var day = ok.data.totalDay
            } else {
                var day = 0
            }

            if (ok.data.totalMonth) {
                var month = ok.data.totalMonth
            } else {
                var month = 0
            }

            if (ok.data.totalYear) {
                var year = ok.data.totalYear
            } else {
                var year = 0
            }
            if (ok.data.totalYear) {
                var total = ok.data.totalYear
            } else {
                var total = 0
            }

            cost.push(
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الیوم</p>
                                        <h4 className="mb-0">IQD {day}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-refund-2-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-success font-size-11"> حسب المبلغ </span> </span>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الشهر</p>
                                        <h4 className="mb-0">IQD {month}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-refund-2-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-success font-size-11"> حسب المبلغ </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا السنة</p>
                                        <h4 className="mb-0">IQD {year}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-refund-2-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-success font-size-11"> حسب المبلغ </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">اجمالی </p>
                                        <h4 className="mb-0">IQD {total}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-refund-2-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-success font-size-11"> حسب المبلغ </span> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
            this.setState({ Cost: cost })
        })
    }

    getByMonth = () => {
        const mNum = $('#getByMonth').val()
        axios.post('/service-invo-cost-month', { mNum }).then((ok) => {
            const cost = []
            if (ok.data.totalMonth) {
                var month = ok.data.totalMonth
            } else {
                var month = 0
            }

            cost.push(
                <div className="col-xl-6 col-lg-6 col-md-12 col-12" >
                    <div className="card">
                        <div className="card-body">
                            <div className="media">
                                <div className="media-body overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2"> شهر الـ {mNum}</p>
                                    <h4 className="mb-0">IQD {month}</h4>
                                </div>
                                <div className="text-primary">
                                    <i className="ri-refund-2-line font-size-35" />
                                </div>
                            </div>
                        </div>
                        <div className="card-body border-top py-3">
                            <div className="text-truncate">
                                <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-success font-size-11"> حسب المبلغ </span> </span>
                            </div>
                        </div>
                    </div>

                </div>

            )
            this.setState({ MonthCost: cost })
        })

        axios.post('/get-report-service-month', { mNum }).then((ok) => {
            let service = []
            for (var i = 0; i < ok.data.length; i++) {
                const id = ok.data[i].id
                service.push(
                    <tr>
                        <td id="idtbl">#{ok.data[i].id}</td>
                        <td>{ok.data[i].item_name}</td>
                        <td>{ok.data[i].cost}</td>
                        <td style={{ direction: 'ltr' }}>{new Date(ok.data[i].created).toLocaleString().toString()} </td>
                        <td>
                            <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delReport(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>
                        </td>
                    </tr>
                )
            }
            this.setState({ MonthServiceList: service })
        })

        axios.post(`/hazal-xservice?id=${mNum}`).then((e) => {
            if (e.data === 13) {
                $('.ready').show();
                $('.bymonth').hide();
            } else {
                $('.ready').hide();
                $('.bymonth').show();
            }
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <div className="float-right">
                                    <select className="custom-select custom-select-sm" id="getByMonth" onChange={this.getByMonth}>
                                        <option value={'13'}>الکل</option>
                                        <option value={1}> شهر الـ ١</option>
                                        <option value={2}>شهر الـ ٢ </option>
                                        <option value={3}>شهر الـ ٣</option>
                                        <option value={4}>شهر الـ ٤</option>
                                        <option value={5}>شهر الـ ٥</option>
                                        <option value={6}>شهر الـ ٦</option>
                                        <option value={7}>شهر الـ ٧</option>
                                        <option value={8}>شهر الـ ٨</option>
                                        <option value={9}>شهر الـ ٩</option>
                                        <option value={10}>شهر الـ ١٠</option>
                                        <option value={11}>شهر الـ ١١</option>
                                        <option value={12}>شهر الـ ١٢</option>

                                    </select>
                                </div>
                                <h4 className="mb-0">تقاریر الخدمات</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);"> احصائيات</a></li>
                                        <li className="breadcrumb-item active">الرئيسية</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ready">
                        {this.state.Cost}
                    </div>
                    <div className="bymonth">
                        <div className="row">
                            {this.state.MonthCost}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="ready table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>اسم المنتج</th>
                                                    <th>حساب الاجمالي</th>
                                                    <th>تاریخ</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">
                                                {this.state.ServiceList}
                                            </tbody>
                                        </table>

                                        <table className="bymonth table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>اسم المنتج</th>
                                                    <th>حساب الاجمالي</th>
                                                    <th>تاریخ</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">
                                                {this.state.MonthServiceList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Report_Rervice
