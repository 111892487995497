import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'

class Cleaning extends Component {
    state = {
        CleaningList: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        axios.post('/readuser').then((ok) => {
            if (ok.data != 'Admin') {
                window.toastr['error']('طلب الاذن مرفوض !')
                this.props.history.push('/dashboard')
            }else{
                this._getCleanings();
            }
        })
    }


    _getCleanings = () => {
        axios.post('/get-cleaning').then((ok) => {
            if (ok.data.length != 0) {
                let cleaning = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id,
                        modId = `Cleaning_${id}`
                    cleaning.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td>{ok.data[i].item}</td>
                            <td>{ok.data[i].brand}</td>
                            <td>{ok.data[i].qty}</td>
                            <td><div className='badge badge-soft-success font-size-12'>{ok.data[i].type_price}</div></td>
                            <td>{ok.data[i].type}</td>
                            <td>{new Date(ok.data[i].expire).toLocaleString().toString()} </td>
                            <td>
                                <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="modal" data-target={`#${modId}`} ><i className="mdi mdi-pencil font-size-18" /></a>
                                <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delCleaning(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>

                                <div className="modal fade" id={modId} tabIndex={-1} role="dialog" aria-labelledby="newCleaning" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newCleaning"> تحدیث المتنج </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="form-group">
                                                    <label htmlFor="full name">اسم المنتج</label>
                                                    <input type="text" className="form-control" id={`edit-item-${id}`} defaultValue={ok.data[i].item} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="full name">اسم الشرکة او وکیل</label>
                                                    <input type="text" className="form-control" id={`edit-brand-${id}`} placeholder="شرکة او وکیل" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">سعر الشراء</label>
                                                    <input type="number" className="form-control" id={`edit-buy_price-${id}`} defaultValue={ok.data[i].buy_price} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">سعر البیع</label>
                                                    <input type="number" className="form-control" id={`edit-type_price-${id}`} defaultValue={ok.data[i].type_price} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">حسب</label>
                                                    <input type="text" className="form-control" id={`edit-type-${id}`} defaultValue={ok.data[i].type} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="phone">عدد</label>
                                                    <input type="number" className="form-control" id={`edit-qty-${id}`} defaultValue={ok.data[i].qty} required />
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="datetime-local-input">مدة صلاحیة</label>
                                                    <input className="form-control" id={`edit-expire-${id}`} type="datetime-local" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type"> حصول علی اشعارات</label>
                                                    <input type="number" className="form-control" id={`edit-alertqty-${id}`} defaultValue={ok.data[i].alertqty} required />
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.modify(id) }} id="submit" className="btn btn-primary" data-dismiss='modal' >تحدیث</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    )
                }
                this.setState({ CleaningList: cleaning })
            }
        })
    }

    AddCleaning = () => {
        const item = $('#item').val(),
            qty = $('#qty').val(),
            type = $('#type').val(),
            price = $('#type_price').val(),
            buy = $('#buy_price').val(),
            expire = $('#expire').val(),

            brand = $('#brand').val(),
            alertqty = $('#alertqty').val()

        if (item.length != 0 && qty.length != 0 && type.length != 0 && price.length != 0 && alertqty.length != 0 && expire.length != 0 && brand.length != 0) {
            axios.post('/add-cleaning', { item, qty, type, price, alertqty, expire, brand, buy }).then((ok) => {
                if (ok.data = 'done') {
                    this._getCleanings();
                    window.toastr['success']('تم تسجيل المنتج جديد بنجاح')
                } else {
                    window.toastr['error']('هذا الالمنتج مسجل بالفعل')
                }
            })
        } else {
            window.toastr['error']('من فضلك املأ الاستمارة')
        }
    }

    delCleaning = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، امسح الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/del-cleaning?id=' + id).then((ok) => {
                    if (ok.data != 'err') {
                        this._getCleanings();
                        window.toastr['success']('تم المسح الالمنتج بنجاح')
                    } else {
                        window.toastr['success']('تم المسح الالمنتج بنجاح')
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                    }
                })
            }
        })
    }

    modify = (id) => {
        const item = $(`#edit-item-${id}`).val(),
            qty = $(`#edit-qty-${id}`).val(),
            type = $(`#edit-type-${id}`).val(),
            expire = $(`#edit-expire-${id}`).val(),
            brand = $(`#edit-brand-${id}`).val(),
            price = $(`#edit-type_price-${id}`).val(),
            buy = $(`#edit-buy_price-${id}`).val(),

            alertqty = $(`#edit-alertqty-${id}`).val()

        axios.post(`/modify-cleaning?id=${id}`, { item, qty, type, price, alertqty, brand, expire, buy }).then((ok) => {
            if (ok.data != 'err') {
                window.toastr['success']('تم التحديث معلومات الالمنتج بنجاح')
                this._getCleanings();
            } else {
                window.toastr['error']('حدث خطأ ما. أعد المحاولة من فضلك')
            }
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <button type="button" className="mb-0 btn btn-primary" data-toggle="modal" data-target="#addcleaning">اضافة جدیدة </button>

                                <div className="modal fade" id='addcleaning' tabIndex={-1} role="dialog" aria-labelledby="addcleaning" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newCleaning">اضافة جدیدة </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="form-group">
                                                    <label htmlFor="full name">اسم المنتج</label>
                                                    <input type="text" className="form-control" id="item" placeholder="کولا" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="full name">اسم الشرکة او وکیل</label>
                                                    <input type="text" className="form-control" id="brand" placeholder="شرکة او وکیل" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">سعر الشراء</label>
                                                    <input type="number" className="form-control" id="buy_price" placeholder="1000" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">سعر البیع</label>
                                                    <input type="number" className="form-control" id="type_price" placeholder="1000" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">حسب</label>
                                                    <input type="text" className="form-control" id="type" placeholder="نفر" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="phone">عدد</label>
                                                    <input type="number" className="form-control" id="qty" placeholder="0" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="datetime-local-input">مدة صلاحیة</label>
                                                    <input className="form-control" id="expire" type="datetime-local" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="type">حصول علی اشعارات</label>
                                                    <input type="number" className="form-control" id="alertqty" placeholder="0" required />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.AddCleaning() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافە</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">ادارة کافي شوب</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>العنصر</th>
                                                    <th>الشرکة او وکیل</th>
                                                    <th>عدد</th>
                                                    <th>سعر</th>
                                                    <th>سعر حسب</th>
                                                    <th>تاریخ الانتهاء</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">

                                                {this.state.CleaningList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cleaning
