import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery'
import swal from 'sweetalert2'
import moment from 'moment'

class Reception extends Component {
    state = {
        GetRoom: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".room-box").filter(function () {
                $(".room-x").filter(function () {
                    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
                })
            });
        });

        $('.paytype').on("click", function () {
            if ($(this).val().toLowerCase() == "custom") {
                $('.cprice-form').show();
            } else {
                $('.cprice-form').hide();
            }
        })
    }

    componentDidMount() {
        this._getRoom();
    }

    uploadPhotoPost = (id) => {
        $('<input type="file" accept="image/*" multiple>').on('change', async function () {

            for (var i = 0; i < this.files.length; ++i) {
                var formData = new FormData();
                formData.append('file', this.files[i])
                await axios.post('/uploadImg', formData).then((ok) => {
                    if (ok.data != 'err') {
                        window.toastr['success']('تم الرفع بنجاح !')
                        $('#imgbox' + id).append(`<img class="viewIMGThumb${id}" src="../../../uploads/${ok.data}" height="100px" width="100px" style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;"/> `)
                    } else {
                        window.toastr['error']('يسمح فقط للصورة بالتحميل!')
                    }
                    $(`.viewIMGThumb${id}`).on('click', function () {
                        $(this).remove();
                    })
                })
            }
        }).click();
    }

    expandGuest = (id) => {
        $(`.add-guest-${id}`).append(`
        <div class="col-lg-3 guest">
            <br>
            <div class="form-group">
                <label for="full name">اسم</label>
                <input type="text" class="form-control fullname-${id}" placeholder="اسم ..." required="" />
            </div>

            <div class="form-group">
                <label for="type">نوع</label>
                <select class="form-control gender-${id}">
                    <option selected>ذکر</option>
                    <option value="انثی">Female</option>
                </select>
            </div>

            <div class="form-group">
                <label for="type">عنوان سکن الحالي</label>
                <input type="text" class="form-control address-${id}" value="السلیمانیة" />
            </div>

            <div class="form-group">
                <label for="type">جنسیة</label>
                <input type="text" class="form-control nationality-${id}" value="العراقی" />
            </div>
        </div>
    `);
    }

    closeModal = (id) => {
        $(`.add-guest-${id}`).empty();
        $(`.phone-${id}`).val('');
        $(`.note-${id}`).val('');
        $(`.price-${id}`).val('');
        $(`.come-${id}`).val('');
        $(`.out-${id}`).val('');
        $(`.viewIMGThumb${id}`).remove();
    }

    empty = (id) => {

        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "سوف یتم اخلاء الغرفة بشکل کامل",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، افرا‌غ الان!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/empty', { id }).then((ok) => {
                    if (ok.data != 'err') {
                        swal.fire({
                            title: 'تم المسح!',
                            text: 'تم المسح المقیمین الموجودین بهذا الغرفة  بنجاح.',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1400
                        })
                        this._getRoom();
                    }
                })
            }
        })
    }

    _getRoom = () => {
        axios.post('/getRoom').then((ok) => {
            if (ok.data.length != 0) {
                const getRoom = []
                for (var i = 0; i < ok.data.length; i++) {
                    const roomID = ok.data[i].id
                    if (ok.data[i].bedUsed != '0') {
                        var isValid = <h4 className="mb-0 badge badge-info font-size-11 room-available">  متواجدین <strong>({ok.data[i].bedUsed})</strong></h4>
                        var guestIcon = <div className="text-primary enter-guest" data-toggle="modal" data-target={`.modal-guest-${ok.data[i].id}`}><i className="ri-user-add-line font-size-24" /></div>
                        var bedstr = <span className="badge badge-soft-info font-size-11 ml-2">السرير: {ok.data[i].beds + '/' + ok.data[i].bedUsed} </span>
                        var optiongrtn = <select className={`form-control paytype type-buy-${roomID}`}><option value="chunk" >حجز بشکل جزئي‌</option>  <option value="custom"> اکتب سعر ...</option></select>
                        var reset = <div className="text-primary enter-guest" onClick={() => { this.empty(roomID) }}><i className="ri-user-unfollow-line font-size-24" /></div>
                    } else {
                        var isValid = <h4 className="mb-0 badge badge-primary font-size-11 room-available"> غیر محجوز</h4>
                        var guestIcon = <div className="text-primary enter-guest" data-toggle="modal" data-target={`.modal-guest-${ok.data[i].id}`}><i className="ri-user-add-line font-size-24" /></div>
                        var bedstr = <span className="badge badge-soft-primary font-size-11 ml-2">السرير: {ok.data[i].beds + '/' + '0'} </span>
                        var optiongrtn = <select className={`form-control paytype type-buy-${roomID}`}><option value="chunk" >حجز بشکل جزئي‌</option> <option value="total" >حجز کامل</option> <option value="custom"> اکتب سعر ...</option></select>
                        var reset = null;
                    }

                    getRoom.push(
                        <div className="col-xl-2 col-lg-3 col-md-4 col-12 room-x" >
                            <div className="card">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="media-body overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2 room-box">{ok.data[i].roomNo}</p>
                                            <br />
                                            {isValid}
                                            <br />
                                        </div>
                                        {guestIcon} &nbsp;  &nbsp;
                                        <Link to={`/guest-room?id=${roomID}`} className="text-primary enter-guest" >
                                            <i className="ri-file-list-3-line font-size-24" />
                                        </Link> &nbsp;  &nbsp;
                                        {reset}

                                    </div>
                                </div>
                                <div className="card-body border-top py-3">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <span className="text-muted"> ایجار: {ok.data[i].price}</span>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            {bedstr}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`modal fade modal-guest-${roomID}`} tabIndex={-1} role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="newStaff">حجز الغرفة </h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <button className="btn btn-primary btn-sm" type="button" onClick={() => { this.expandGuest(roomID) }}>
                                                <i className="ri-user-add-line font-size-btn " />  &nbsp; اضافە زائر
                                                        </button>
                                            <br /> <br />
                                            <div className={`row add-guest-${roomID}`}></div>
                                            <br />
                                            <br />
                                            <div className="form-group row">
                                                <label htmlFor="phone" className="col-2 col-form-label">هاتف</label>
                                                <div className="col-10">
                                                    <input type="number" className={`form-control phone-${roomID}`} placeholder="07 ..." required />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="phone" className="col-2 col-form-label"> أسم المجموعة</label>
                                                <div className="col-10">
                                                    <input type="text" className={`form-control isgroup-${roomID}`} placeholder="اسم المجموعة ..." required />
                                                    <small id="timehelp" className="form-text text-muted">
                                                        <div className="alert alert-warning" role="alert">
                                                            اترك الخانة اذا لم يتبع لاي مجموعة
                                                            </div>
                                                    </small>
                                                </div>
                                            </div>


                                            <div className="form-group row">
                                                <label htmlFor="type" className="col-2 col-form-label">ملاحضة</label>
                                                <div className="col-10">
                                                    <textarea className={`form-control note-${roomID}`} rows="3"></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="datetime-local-input" className="col-2 col-form-label">ادخال</label>
                                                <div className="col-10">
                                                    <input className={`form-control come-${roomID}`} type="datetime-local" required />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="datetime-local-input" className="col-2 col-form-label">اخراج</label>
                                                <div className="col-10">
                                                    <input className={`form-control out-${roomID}`} type="datetime-local" required />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-2 col-form-label">عدد اللیالي </label>
                                                <div className="col-10">
                                                    <input className={`form-control night-val-${roomID}`} type="number" />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-md-2 col-form-label">نوع الحجز</label>
                                                <div className="col-md-10">
                                                    {optiongrtn}
                                                </div>
                                            </div>


                                            <div className={`form-group cprice-form row`} style={{ display: 'none' }}>
                                                <label className="col-2 col-form-label">اکتب السعر</label>
                                                <div className="col-10">
                                                    <input className={`form-control cprice-val-${roomID}`} type="number" />
                                                </div>
                                            </div>


                                            <div className="form-group row">
                                                <label htmlFor="price" className="col-2 col-form-label">مرفق</label>
                                                <div className="col-10">
                                                    <div className="form-control up" onClick={() => { this.uploadPhotoPost(roomID) }}>
                                                        <i className="ri-upload-cloud-2-fill font-size-18"></i>
                                                    </div>
                                                    <br />
                                                    <div align="center" id={`imgbox${roomID}`}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" onClick={() => { this.addGuest(roomID) }} id="submit" className="btn btn-primary" data-dismiss='modal' > &nbsp; حجز الغرفة &nbsp;</button>
                                            <button type="button" className="btn btn-danger" onClick={() => { this.closeModal(roomID) }}>&nbsp; افراغ &nbsp;</button>
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">&nbsp; اغلاق &nbsp;</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    )
                }
                this.setState({ GetRoom: getRoom })
            }
        })
    }

    addGuest = (id) => {
        const fullName = $(`.fullname-${id}`).map((_, el) => el.value).get(),
            gender = $(`.gender-${id} :selected`).map((_, el) => el.value).get(),
            nationality = $(`.nationality-${id}`).map((_, el) => el.value).get(),
            address = $(`.address-${id}`).map((_, el) => el.value).get(),
            phone = $(`.phone-${id}`).val(),
            isgroup = $(`.isgroup-${id}`).val(),
            note = $(`.note-${id}`).val(),
            pricestr = $(`.type-buy-${id}`).val(),
            numGuest = fullName.length,
            roomID = id,
            night = $(`.night-val-${id}`).val(),
            come = $(`.come-${id}`).val(),
            out = $(`.out-${id}`).val(),
            srcs = $(`.viewIMGThumb${id}`).get().map((el) => { return el.src.split('/uploads/')[1] })

        if (pricestr != 'custom') {
            var price = pricestr
        } else {
            var price = $(`.cprice-val-${id}`).val();
        }

        if (srcs.length != 0) {
            var attachment = srcs.toString()
        } else {
            var attachment = 'none'
        }


        axios.post('/add-guest', { fullName, gender, nationality, address, isgroup, phone, note, price, numGuest, roomID, come, out, attachment, night }).then((ok) => {
            if (ok.data === 'done') {
                $(`.add-guest-${id}`).empty();
                window.toastr['success']('مكتملة بنجاح')
                this.forceUpdate(this._getRoom());
                $(`.phone-${id}`).val('');
                $(`.note-${id}`).val('');
                $(`.price-${id}`).val('');
                $(`.come-${id}`).val('');
                $(`.out-${id}`).val('');
                $(`.viewIMGThumb${id}`).remove();
            } else {
                if (ok.data === 'err') {
                    window.toastr['error']('یرجى ملء النموذج كما هو مطلوب')
                }
                if (ok.data === 'x') {
                    window.toastr['error']('یرجى ملء النموذج كما هو مطلوب')
                }
                if (ok.data === 'errlength') {
                    window.toastr['error'](' لیس لدیك مکان کافی ')
                }
            }
        })

    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">استعلامات</h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">الوحة التحکم</a></li>
                                        <li className="breadcrumb-item active">استعلامات</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input id="myInput" type="text" className="form-control" placeholder="بحث ..." />
                                    <span className="ri-search-line" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="row">
                                {this.state.GetRoom}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Reception
