import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment'

class Modify_guest extends Component {
    state = {
        Guest: []
    }

    componentDidUpdate() {
        $('.paytype').on("click", function () {
            if ($(this).val().toLowerCase() == "custom") {
                $('.cprice-form').show();
            } else {
                $('.cprice-form').hide();
            }
        })
    }

    componentDidMount() {
        this._getGuest();
    }

    uploadPhotoPost = () => {
        $('<input type="file" accept="image/*" multiple>').on('change', async function () {
            for (var i = 0; i < this.files.length; ++i) {
                var formData = new FormData();
                formData.append('file', this.files[i])
                await axios.post('/uploadImg', formData).then((ok) => {
                    if (ok.data != 'err') {
                        window.toastr['success']('تم الرفع بنجاح !')
                        $('#imgbox').append(`<img class="viewIMGThumb" src="../../../uploads/${ok.data}" height="100px" width="100px" style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;"/> `)
                    } else {
                        window.toastr['error']('يسمح فقط للصورة بالتحميل!')
                    }
                    $(`.viewIMGThumb`).on('click', function () {
                        $(this).remove();
                    })
                })
            }
        }).click();
    }

    _getGuest = () => {
        const id = window.location.href.split('?id=')[1]
        axios.post('/get-guest?id=' + id).then((ok) => {
            if (ok.data.length != 0) {

                let guest = []
                for (var i = 0; i < ok.data.length; i++) {
                    const coming_str = ok.data[0].coming,
                        going_str = ok.data[0].going,
                        coming = coming_str.slice(0, coming_str.lastIndexOf(".")),
                        going = going_str.slice(0, going_str.lastIndexOf("."));

                    guest.push(
                        <div className="card-body">
                            <div className="form-group row">
                                <label htmlFor="phone" className="col-2 col-form-label">اسم</label>
                                <div className="col-10">
                                    <input type="text" id="name" className="form-control" defaultValue={ok.data[0].fullName} />
                                    <small id="modifyHelp" className="form-text text-muted">
                                        یرجی استخدام (,) فارزة الانجلیزیة عند زیادة مقیم اخری

                                    </small>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="phone" className="col-2 col-form-label">نوع</label>
                                <div className="col-10">
                                    <input type="text" id="gender" className="form-control" defaultValue={ok.data[0].gender} required />
                                    <small id="modifyHelp" className="form-text text-muted">
                                        یرجی استخدام (,) فارزة الانجلیزیة عند زیادة نوع اخری

                                    </small>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="phone" className="col-2 col-form-label">السکن الحالي</label>
                                <div className="col-10">
                                    <input type="text" id="address" className="form-control" defaultValue={ok.data[0].address} required />
                                    <small id="modifyHelp" className="form-text text-muted">
                                        یرجی استخدام (,) فارزة الانجلیزیة عند زیادة مکان اخری

                                    </small>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="phone" className="col-2 col-form-label">جنسیة</label>
                                <div className="col-10">
                                    <input type="text" id="nation" className="form-control" defaultValue={ok.data[0].nationality} required />
                                    <small id="modifyHelp" className="form-text text-muted">
                                        یرجی استخدام (,) فارزة الانجلیزیة عند زیادة جنسية اخری

                                    </small>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="phone" className="col-2 col-form-label">هاتف</label>
                                <div className="col-10">
                                    <input type="number" id="phone" className="form-control" defaultValue={ok.data[0].phone} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="phone" className="col-2 col-form-label"> أسم المجموعة</label>
                                <div className="col-10">
                                    <input type="text" className={`form-control isgroup`} id="isgroup" defaultValue={ok.data[0].isgroup} required />
                                    <small id="modifyHelp" className="form-text text-muted">
                                        <div className="alert alert-warning" role="alert">
                                            اترك الخانة اذا لم يتبع لاي مجموعة
                                        </div>
                                    </small>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="type" className="col-2 col-form-label">ملاحضة</label>
                                <div className="col-10">
                                    <textarea className="form-control" id="note" rows="3">{ok.data[0].note}</textarea>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="datetime-local-input" className="col-2 col-form-label">ادخال</label>
                                <div className="col-10">
                                    <input className="form-control" id="come" type="datetime-local" defaultValue={coming} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="datetime-local-input" className="col-2 col-form-label">اخراج</label>
                                <div className="col-10">
                                    <input className="form-control" id="out" type="datetime-local" defaultValue={going} required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-2 col-form-label">عدد اللیالي </label>
                                <div className="col-10">
                                    <input className="form-control" id="night" type="number" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-md-2 col-form-label">نوع الحجز</label>
                                <div className="col-md-10">
                                    <select className="form-control paytype" id="getType">
                                        <option value="chunk" >حجز بشکل جزئي‌</option>
                                        <option value="custom"> اکتب سعر ...</option>
                                    </select>
                                </div>
                            </div>

                            <div className={`form-group cprice-form row`} style={{ display: 'none' }}>
                                <label className="col-2 col-form-label">اکتب السعر</label>
                                <div className="col-10">
                                    <input className="form-control" id="customPrice" type="number" />
                                </div>
                            </div>


                            <div className="form-group row">
                                <label htmlFor="price" className="col-2 col-form-label">مرفق</label>
                                <div className="col-10">
                                    <div className="form-control up" onClick={() => { this.uploadPhotoPost(id) }}>
                                        <i className="ri-upload-cloud-2-fill font-size-18"></i>
                                    </div>
                                    <br />
                                    <div align="center" id="imgbox"></div>
                                </div>
                            </div>

                            <input id="roomid" className="form-control" defaultValue={ok.data[0].roomID} required type="hidden" />

                            <div className="form-group row">
                                <div className="col-10">
                                    <button type="button" onClick={() => { this.updateGuest(id) }} id="submit" className="btn btn-primary" data-dismiss='modal' > &nbsp; تحدیث المعلومات  &nbsp;</button>
                                </div>
                            </div>

                        </div>
                    )
                }
                this.setState({ Guest: guest })
            }
        })
    }

    updateGuest = (id) => {
        const fullName = $('#name').val(),
            gender = $('#gender').val(),
            nationality = $('#nation').val(),
            phone = $('#phone').val(),
            address = $('#address').val(),
            isgroup = $('#isgroup').val(),
            note = $('#note').val(),
            roomID = $('#roomid').val(),
            srcs = $(".viewIMGThumb").get().map((el) => { return el.src.split('/uploads/')[1] }),
            come = $("#come").val(),
            out = $("#out").val(),
            getType = $('#getType').val(),
            night = $('#night').val()

        if (fullName.includes(',')) {
            var numGuest = fullName.split(',').length
        } else {
            var numGuest = 1
        }

        if (getType != 'custom') {
            var price = getType
        } else {
            var price = $("#customPrice").val();
        }

        if (srcs.length != 0) {
            var attachment = srcs.toString()
        } else {
            var attachment = 'none'
        }

        axios.post(`/update-guest?id=${id}`, { fullName, gender, address, isgroup, nationality, phone, note, roomID, attachment, come, out, price, night, numGuest }).then((ok) => {
            if (ok.data != 'err') {
                window.toastr['success']('تم التحدیث بنجاح')
                setTimeout(() => {
                    this.props.history.push(`/guest-room?id=${roomID}`)
                }, 300);
            } else {
                window.toastr['error']('حدث خطا ')
                setTimeout(() => {
                    this.props.history.push(`/guest-room?id=${roomID}`)
                }, 300);
            }
        })
    }


    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">تحریر المعلومات الزائر</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                {this.state.Guest}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modify_guest
