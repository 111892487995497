import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'

class Service extends Component {
    state = {
        ServiceList: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        axios.post('/readuser').then((ok) => {
            if (ok.data != 'Admin') {
                window.toastr['error']('طلب الاذن مرفوض !')
                this.props.history.push('/dashboard')
            } else {
                this._getServices();
            }
        })
    }

    _getServices = () => {
        axios.post('/get-service').then((ok) => {
            if (ok.data.length != 0) {
                let service = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id,
                        modId = `Service_${id}`
                    service.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td>{ok.data[i].item}</td>
                            <td><div className='badge badge-soft-success font-size-12'>{ok.data[i].price}</div></td>
                            <td>
                                <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="modal" data-target={`#id-${modId}`} ><i className="mdi mdi-pencil font-size-18" /></a>
                                <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delService(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>

                                <div className="modal fade" id={`id-${modId}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newService">تحریر الخدمة </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="full name">نوع الخدمة</label>
                                                    <input type="text" className="form-control" id={`edit-item-${id}`} defaultValue={ok.data[i].item} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="phone">سعر</label>
                                                    <input type="number" className="form-control" id={`edit-price-${id}`} defaultValue={ok.data[i].price} required />
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.modify(id) }} id="submit" className="btn btn-primary" data-dismiss='modal' >تحدیث</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>

                    )
                }
                this.setState({ ServiceList: service })
            }
        })
    }

    AddService = () => {
        const item = $('#item').val(),
            price = $('#price').val()

        if (item.length != 0 && price.length != 0) {
            axios.post('/add-service', { item, price }).then((ok) => {
                if (ok.data != 'done') {
                    window.toastr['error']('هذا خدمة مسجل بالفعل')

                } else {
                    this._getServices();
                    window.toastr['success']('تم تسجيل خدمة جديد بنجاح')
                }
            })
        } else {
            window.toastr['error']('من فضلك املأ الاستمارة')
        }
    }

    delService = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، امسح الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/del-service?id=' + id).then((ok) => {
                    if (ok.data != 'err') {
                        this._getServices();
                        window.toastr['success']('تم المسح خدمة بنجاح')
                    } else {
                        window.toastr['success']('تم المسح خدمة بنجاح')
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                    }
                })
            }
        })
    }


    modify = (id) => {
        const item = $(`#edit-item-${id}`).val(),
            price = $(`#edit-price-${id}`).val()

        axios.post(`/modify-service?id=${id}`, { item, price }).then((ok) => {
            if (ok.data != 'err') {
                window.toastr['success']('تم التحديث معلومات خدمة بنجاح')
                this._getServices();
            } else {
                window.toastr['error']('حدث خطأ ما. أعد المحاولة من فضلك')
            }
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <button type="button" className="mb-0 btn btn-primary" data-toggle="modal" data-target="#addservice">اضافة خدمة جدیدة </button>

                                <div className="modal fade" id='addservice' tabIndex={-1} role="dialog" aria-labelledby="addservice" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newService">اضافة خدمة جدیدة </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="full name">نوع الخدمة</label>
                                                    <input type="text" className="form-control" id="item" placeholder="تکسی ..." required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="phone">سعر</label>
                                                    <input type="number" className="form-control" id="price" placeholder="00.00 IQD" required />
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.AddService() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافە</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">ادارة الخدمات</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>نوع الخدمة</th>
                                                    <th>سعر</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">
                                                {this.state.ServiceList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Service
