import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'
import moment from 'moment'

class Guests extends Component {
    state = {
        GuestsList: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        this._getGuests();
    }

    prnt = (print) => {
        $('.modal-backdrop').remove();
        $(".page-content").html($(print).html());
        window.print();
        $(".page-content").html("");
    }

    prntg = (print) => {
        $('.modal-backdrop').remove();
        $(".page-content").html($(print).html());
        window.print();
        $(".page-content").html("");
    }

    del = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، امسح الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/del-guest', { id }).then((ok) => {
                    if (ok.data != 'err') {
                        swal.fire({
                            title: 'تم المسح!',
                            text: 'تم المسح الزائر بنجاح.',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1400
                        })
                        this._getGuests();
                    }
                })
            }
        })
    }

    _getGuests = () => {
        axios.post('/get-guests').then((ok) => {
            if (ok.data.length != 0) {
                let guests = []
                for (var i = 0; i < ok.data.length; i++) {
                    const name = ok.data[i].fullName,
                        fullname = [],
                        gender = [],
                        address = [],
                        nationality = [],
                        id = ok.data[i].id,
                        personINV = []

                    if (name.includes(',')) {
                        for (var j = 0; j < name.split(',').length; j++) {
                            fullname.push(<div dangerouslySetInnerHTML={{ __html: `${name.split(',')[j]}<br/>` }} />)
                            gender.push(<div dangerouslySetInnerHTML={{ __html: `${ok.data[i].gender.split(',')[j]}<br/>` }} />)
                            address.push(<div dangerouslySetInnerHTML={{ __html: `${ok.data[i].address.split(',')[j]}<br/>` }} />)
                            nationality.push(<div dangerouslySetInnerHTML={{ __html: `${ok.data[i].nationality.split(',')[j]}<br/>` }} />)
                            personINV.push(
                                <>
                                    <div>
                                        الاسم: {name.split(',')[j]} <br />
                                النوع: {ok.data[i].gender.split(',')[j]} <br />
                                عنوان السکن: {ok.data[i].gender.split(',')[j]} <br />
                                الجنسیة: {ok.data[i].nationality.split(',')[j]} <br />
                                    </div>
                                    <br />
                                </>
                            )

                        }
                    } else {
                        fullname.push(ok.data[i].fullName)
                        gender.push(ok.data[i].gender)
                        address.push(ok.data[i].address)
                        nationality.push(ok.data[i].nationality)
                        personINV.push(
                            <div>
                                الاسم: {name} <br />
                            النوع: {ok.data[i].gender} <br />
                            عنوان السکن: {ok.data[i].address} <br />
                            الجنسیة: {ok.data[i].nationality} <br />
                            </div>
                        )
                    }

                    if (ok.data[i].ispaid === '0') {
                        var ispaid = "غیر مدفوع"
                    } else {

                        var ispaid = "تم الدفع"
                    }

                    const attach = []
                    for (var j = 0; j < ok.data[i].attachment.split(',').length; j++) {
                        attach.push(<div className="col-md-6"><img className="img-fluid" src={ok.data[i].attachment.split(',')[j]} /></div>)
                    }

                    if(ok.data[i].ispaid != 1){
                        var deltbn = <button className="btn btn-danger btn-sm delguest" type="button" onClick={() => { this.del(id) }}>مسح الزائر</button>
                        var editbtn = <li className="nav-item">
                        <a className="nav-link paynow" href={`/modify-guest?id=${id}`} >
                            <i className="ri-check-line font-size-20" />
                            <span className="mt-2 d-none d-sm-block"> تعدیل معلومات </span>
                        </a>
                    </li>
                    }else{
                        var editbtn = null;
                        var deltbn = null;
                    }

                    guests.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td>{fullname}</td>
                            <td>{gender}</td>
                            <td>{nationality}</td>
                            <td>{address}</td>
                            <td>{ok.data[i].isgroup}</td>
                            <td>{ok.data[i].phone}</td>
                            <td>{ok.data[i].roomNo}</td>
                            <td style={{ direction: 'ltr' }}>{new Date(ok.data[i].coming).toLocaleString().toString()} </td>
                            <td style={{ direction: 'ltr' }}>{new Date(ok.data[i].going).toLocaleString().toString()} </td>


                            <td>{ok.data[i].cost}</td>
                            <td>{ispaid}</td>


                            <td className="action">
                                <button className="btn btn-primary btn-sm other-option" type="button" data-toggle="modal" data-target={`.expandaction-${id}`}>
                                    خیارات اخری
                                    </button><br />
                                {deltbn}

                                <div className={`modal fade  bs-example-modal-center expandaction-${id}`} tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-body">

                                                <ul className="nav nav-pills nav-justified">
                                                    <li className="nav-item">
                                                        <a href="javascript: void(0);" className="nav-link cafx" data-toggle="modal" data-target={`#idcaf-${id}`}>
                                                            <i className="ri-file-list-3-line font-size-20" />
                                                            <span className="mt-2 d-none d-sm-block">  فتح قائمة كافيتريا</span>
                                                        </a>
                                                        <div className="modal fade" id={`idcaf-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" >قائمة كافيتريا</h5>
                                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">×</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <div dangerouslySetInnerHTML={{ __html: ok.data[i].cafeList }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a href="javascript: void(0);" className="nav-link cleax" data-toggle="modal" data-target={`#idcle-${id}`}>
                                                            <i className="ri-file-list-3-line font-size-20" />
                                                            <span className="mt-2 d-none d-sm-block">  فتح قائمة مواد التنظیفات</span>
                                                        </a>
                                                        <div className="modal fade" id={`idcle-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" >قائمة مواد التنظیفات</h5>
                                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">×</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <div dangerouslySetInnerHTML={{ __html: ok.data[i].cleanList }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a href="javascript: void(0);" className="nav-link servx" data-toggle="modal" data-target={`#idserv-${id}`}>
                                                            <i className="ri-file-list-3-line font-size-20" />
                                                            <span className="mt-2 d-none d-sm-block"> فتح قائمە خدمات</span>
                                                        </a>
                                                        <div className="modal fade" id={`idserv-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" >  قائمە خدمات </h5>
                                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">×</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <div dangerouslySetInnerHTML={{ __html: ok.data[i].serviceList }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </li>

                                                    <li className="nav-item">
                                                        <a href="javascript: void(0);" className="nav-link attax" data-toggle="modal" data-target={`#id-${id}`}>
                                                            <i className="ri-file-list-3-line font-size-20" />
                                                            <span className="mt-2 d-none d-sm-block"> فتح مستمسكات</span>
                                                        </a>
                                                        <div className="modal fade bd-example-modal-lg" id={`id-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" >  مستمسكات  </h5>
                                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">×</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body" id={"guest-atta-" + id}>
                                                                        <div className="row">
                                                                            {attach}
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer btn-serv">
                                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                                        <button type="button" className="btn btn-primary" onClick={() => { this.prntg("#guest-atta-" + id) }}>طباعە</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <br />
                                                <ul className="nav nav-pills nav-justified">
                                                    <li className="nav-item">
                                                        <a href="javascript: void(0);" data-toggle="tab" aria-expanded="true" className="nav-link checkout" data-toggle="modal" data-target={`.prnt-${id}`}>
                                                            <i className="ri-file-list-3-fill font-size-20" />
                                                            <span className="mt-2 d-none d-sm-block"> کشف الحساب</span>
                                                        </a>
                                                    </li>
                                                    {editbtn}
                                                </ul>

                                                <div className={`modal fade bd-example-modal-lg prnt-${id}`} tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" > کشف الحساب </h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="container" id={`guest-inv-${id}`}>
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            فاتورة/
                                                                                <strong> {ok.data[i].id}</strong>
                                                                            <span className="float-right"> <strong>حالة:</strong> {ispaid}</span>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row mb-4">
                                                                                <div className="col-sm-5">
                                                                                    <h6 className="mb-3">معلومات المقیم</h6>
                                                                                    {personINV}
                                                                                </div>
                                                                                <div className="col-sm-5">
                                                                                    <h6 className="mb-3">معلومات الغرفة</h6>
                                                                                    <div><strong>الغرفة:</strong> {ok.data[i].roomNo}</div>
                                                                                    <br />
                                                                                    <div style={{ direction: 'ltr' }}><strong>تاریخ الادخال</strong>
                                                                                        <br />
                                                                                        {new Date(ok.data[i].coming).toLocaleString().toString()}</div><br />
                                                                                    <div style={{ direction: 'ltr' }}><strong>تاریخ الخروج</strong>
                                                                                        <br />
                                                                                        {new Date(ok.data[i].going).toLocaleString().toString()} </div><br />

                                                                                    <div><strong>هاتف المقیم</strong>
                                                                                        <br />
                                                                                        {ok.data[i].phone} </div>
                                                                                </div>
                                                                                <div className="col-sm-2">
                                                                                    <img className="logoInv" src="../../assets/images/authentication-bg.jpg" />
                                                                                    <br /> <br />
                                                                                    <h6 className="mb-3"><strong>Lavender Motel</strong></h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="table-responsive-sm">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="center">#</th>
                                                                                            <th>نوع الفاتورة</th>
                                                                                            <th>تفاصیل</th>
                                                                                        </tr>
                                                                                    </thead>

                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="center">١</td>
                                                                                            <td className="left strong">
                                                                                                فاتورة کافيتریا
                                                                                                    </td>
                                                                                            <td className="left">
                                                                                                <div dangerouslySetInnerHTML={{ __html: ok.data[i].cafeList }} />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="center">٢</td>
                                                                                            <td className="left">فاتورة الخدمات</td>
                                                                                            <td className="left">
                                                                                                <div dangerouslySetInnerHTML={{ __html: ok.data[i].serviceList }} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-12 col-sm-12 ml-auto">
                                                                                    <table className="table table-clear">
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td className="left">
                                                                                                    <strong>عدد اشخاص</strong>
                                                                                                </td>
                                                                                                <td className="right"> {ok.data[i].numguest}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="left">
                                                                                                    <strong>عدد اللیالي</strong>
                                                                                                </td>
                                                                                                <td className="right"> {ok.data[i].numNight}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="left">
                                                                                                    <strong>اجرة الغرفة </strong>
                                                                                                </td>
                                                                                                <td className="right"> {ok.data[i].roomPrice} IQD </td>
                                                                                            </tr>

                                                                                            <tr id="kalfa">
                                                                                                <td className="left">
                                                                                                    <strong>حساب الاجمالي</strong>
                                                                                                </td>
                                                                                                <td className="right">
                                                                                                    <strong>{ok.data[i].cost} IQD</strong>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-footer">
                                                                            <div className="row">
                                                                                <div className="col-lg-6 col-sm-6 ml-auto">
                                                                                    <p> <strong>عنوان:  </strong>
                                                                                    السليمانية - رابرين - مقابل مطار سليمانية الدولي</p>
                                                                                    <p> <strong> رقم الهاتف:  </strong>
                                                                                     ‎07509473535 - ‎0770 947 3535</p>
                                                                                </div>
                                                                                <div className="col-lg-6 col-sm-6 ml-auto">
                                                                                    <p><strong>تاریخ السحب الفاتورة</strong> </p>
                                                                                    <p style={{ direction: 'ltr' }}>{new Date().toLocaleString().toString()}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer btn-serv">
                                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                                    <button type="button" className="btn btn-primary" onClick={() => { this.prnt(`#guest-inv-${id}`) }}>طباعە</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    )
                }
                this.setState({ GuestsList: guests })
            }
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">ادارة المقیم</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" id="htmltable" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light geustnow">
                                                <tr>
                                                    <th>#</th>
                                                    <th>الاسم</th>
                                                    <th>نوع</th>
                                                    <th>جنسية</th>
                                                    <th>سکن الحالي</th>
                                                    <th>المجموعة</th>
                                                    <th>هاتف</th>
                                                    <th>الغرفة</th>
                                                    <th>ادخال</th>
                                                    <th>اخراج</th>
                                                    <th>حساب الاجمالي</th>
                                                    <th>حساب</th>
                                                    <th>الخيارات</th>
                                                </tr>
                                            </thead>
                                            <tbody id="myTable" className="geustnow myTable">
                                                {this.state.GuestsList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Guests
