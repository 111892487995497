import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'
class Users extends Component {
    state = {
        UsersList: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        axios.post('/readuser').then((ok) => {
            if (ok.data != 'Admin') {
                window.toastr['error']('طلب الاذن مرفوض !')
                this.props.history.push('/dashboard')
            } else {
                this._getUsers();
            }
        })
    }

    _getUsers = () => {
        axios.post('/get-users').then((ok) => {
            if (ok.data.length != 0) {
                let users = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id,
                        modId = `user_${id}`
                    if (ok.data[i].role.includes('Admin')) {
                        var setColor = 'badge badge-soft-success font-size-12'
                    } else {
                        var setColor = 'badge badge-soft-info font-size-12'
                    }
                    users.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td>{ok.data[i].username}</td>
                            <td><div className={setColor}>{ok.data[i].role}</div></td>
                            <td style={{ direction: 'ltr' }}>{new Date(ok.data[i].created).toLocaleString().toString()} </td>
                            <td>
                                <a href="javascript:void(0);" className="mr-3 text-primary" data-toggle="modal" data-target={`#${modId}`} ><i className="mdi mdi-pencil font-size-18" /></a>
                                <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delUser(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>

                                <div className="modal fade" id={modId} tabIndex={-1} role="dialog" aria-labelledby="newUser" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newUser">تحریر المستخدم </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="username">اسم المستخدم</label>
                                                    <input type="text" className="form-control" id={`edit-username-${id}`} defaultValue={ok.data[i].username} />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="password">کلمة مرور</label>
                                                    <input type="password" className="form-control" id={`edit-password-${id}`} defaultValue="default" />
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-2 col-form-label">وظيفة</label>
                                                    <div className="col-md-10">
                                                        <select className="form-control" id={`edit-role-${id}`}>
                                                            <option>User</option>
                                                            <option>Admin</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" id="submit" className="btn btn-primary" data-dismiss='modal' onClick={() => { this.modify(id) }}>تحدیث</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    )
                }
                this.setState({ UsersList: users })
            }
        })
    }

    AddUser = () => {
        const username = $('#username').val(),
            password = $('#password').val(),
            role = $('#role').val()
        if (username.length != 0 && password.length != 0 && !role.includes('Select')) {
            axios.post('/add-user', { username, password, role }).then((ok) => {
                if (ok.data != 'err') {
                    this._getUsers();
                    window.toastr['success']('تم تسجيل مستخدم جديد بنجاح')
                } else {
                    window.toastr['error']('هذا المستخدم مسجل بالفعل')
                }
            })
        } else {
            window.toastr['error']('من فضلك املأ الاستمارة')
        }
    }

    delUser = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، امسح الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/del-user?id=' + id).then((ok) => {
                    if (ok.data != 'err') {
                        this._getUsers();
                        window.toastr['success']('تم المسح مستخدم بنجاح')
                    } else {
                        window.toastr['success']('تم المسح مستخدم بنجاح')
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                    }
                })
            }
        })
    }



    modify = (id) => {
        const username = $(`#edit-username-${id}`).val(),
            password = $(`#edit-password-${id}`).val(),
            role = $('#edit-role-' + id).val()
        axios.post(`/modify-user?id=${id}`, { username, password, role }).then((ok) => {
            if (ok.data != 'err') {
                window.toastr['success']('تم التحديث مستخدم بنجاح')
                this._getUsers();
            } else {
                window.toastr['error']('حدث خطأ ما. أعد المحاولة من فضلك')
            }
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <button type="button" className="mb-0 btn btn-primary" data-toggle="modal" data-target="#newUser">اضافة مستخدم جدید </button>

                                <div className="modal fade" id="newUser" tabIndex={-1} role="dialog" aria-labelledby="newUser" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newUser">اضافة مستخدم جدید </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="username">اسم المستخدم</label>
                                                    <input type="text" className="form-control" id="username" placeholder="اسم المستخدم" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="password">کلمة مرور</label>
                                                    <input type="password" className="form-control" id="password" required />
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-md-1 col-form-label">الوظیفة</label>
                                                    <div className="col-md-11">
                                                        <select className="form-control" id="role">
                                                            <option>User</option>
                                                            <option>Admin</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" id="submit" className="btn btn-primary" data-dismiss='modal' onClick={() => { this.AddUser() }}>اضافة</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">ادارة المستخدمین</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>اسم المستخدم</th>
                                                    <th>وظيفة</th>
                                                    <th>تاریخ التسجیل</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">
                                                {this.state.UsersList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Users
