import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { Link, Route } from 'react-router-dom';
import swal from 'sweetalert2'
import moment from 'moment'

class GuestRoom extends Component {
    state = {
        GuestsList: [],
        Services: [],
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $("#myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        this._getGuests();
    }

    GetService = (id) => {
        axios.post('/get-service').then((ok) => {
            $('#service').empty();
            if (ok.data.length != 0) {
                for (var i = 0; i < ok.data.length; i++) {
                    const price = ok.data[i].price,
                        item = ok.data[i].item.toString()
                    if (item.includes(' ')) {
                        var item_str = item.replace(/ +/g, '_')
                    } else {
                        var item_str = item
                    }
                    $('#service').append(`<option value=${price}|${item_str}>${item}</option>`)
                }
            }

            $("#service").on("change", function () {
                $("#show_price").text($("#service").val().split('|')[0]);
                $('#item').val($("#service").val().split('|')[1].replace(/_+/g, ' '));
                $('#price').val($("#service").val().split('|')[0]);
            }).trigger("change");
            $('#guestID').val(id)

        })
    }

    AddServiceToGuest = () => {
        const guest_id = $('#guestID').val(),
            item = $('#item').val(),
            price = $('#price').val()

        axios.post('/serv-guest', { guest_id, item, price }).then((ok) => {
            if (ok.data != 'done') {
                window.toastr['error']('خطا')
            } else {
                this._getGuests();
                window.toastr['success']('تم اضافە خدمة جديد بنجاح')
            }
        })
    }

    GetCaffe = (id) => {
        axios.post('/get-cafee').then((ok) => {
            $('#caffe').empty();
            if (ok.data.length != 0) {
                for (var i = 0; i < ok.data.length; i++) {
                    const price = ok.data[i].type_price,
                        item = ok.data[i].item.toString(),
                        type = ok.data[i].type.toString()

                    if (item.includes(' ')) {
                        var item_str = item.replace(/ +/g, '_')
                        var type_str = type.replace(/ +/g, '_')
                    } else {
                        var item_str = item
                        var type_str = type
                    }

                    $('#caffe').append(`<option value=${price}|${item_str}*${type_str}>${item}</option>`)
                }
            }
            $("#caffe").on("change", function () {
                $("#show_price_caffe").text(`${$("#caffe").val().split('|')[1].split('*')[1]} = ${$("#caffe").val().split('|')[0]}`);
                $('#caffe_item').val($("#caffe").val().split('|')[1].split('*')[0]);
                $('#caffe_price').val($("#caffe").val().split('|')[0]);
                $('#caffe_price_type').val($("#caffe").val().split('|')[1].split('*')[1])

            }).trigger("change");
            $('#caffe_guestID').val(id)

        })
    }

    AddCaffeToGuest = () => {
        const guest_id = $('#caffe_guestID').val(),
            itemstr = $('#caffe_item').val(),
            price = $('#caffe_price').val(),
            price_type = $('#caffe_price_type').val(),
            qty = $('#qty').val()
        if (itemstr.includes('_')) {
            var item = itemstr.replace(/_+/g, ' ')
        } else {
            var item = itemstr
        }
        axios.post('/caffe-guest', { guest_id, item, price, price_type, qty }).then((ok) => {
            if (ok.data === 'done') {
                this._getGuests();
                window.toastr['success']('تم اضافە الطلب بنجاح')
            } else {
                if (ok.data != 'err') {
                    window.toastr['error']('لیس لدیك کمیة کافیة لهذا الطلب، یرجی اضافة کمیة !')
                } else {
                    window.toastr['error']('خطا')
                }
            }

        })

    }

    GetCleaning = (id) => {
        axios.post('/get-cleaning').then((ok) => {
            $('#cleaning').empty();
            if (ok.data.length != 0) {
                for (var i = 0; i < ok.data.length; i++) {
                    const price = ok.data[i].type_price,
                        item = ok.data[i].item.toString(),
                        type = ok.data[i].type.toString()

                    if (item.includes(' ')) {
                        var item_str = item.replace(/ +/g, '_')
                        var type_str = type.replace(/ +/g, '_')
                    } else {
                        var item_str = item
                        var type_str = type
                    }

                    $('#cleaning').append(`<option value=${price}|${item_str}*${type_str}>${item}</option>`)
                }
            }
            $("#cleaning").on("change", function () {
                $("#show_price_cleaning").text(`${$("#cleaning").val().split('|')[1].split('*')[1]} = ${$("#cleaning").val().split('|')[0]}`);
                $('#cleaning_item').val($("#cleaning").val().split('|')[1].split('*')[0]);
                $('#cleaning_price').val($("#cleaning").val().split('|')[0]);
                $('#cleaning_price_type').val($("#cleaning").val().split('|')[1].split('*')[1])

            }).trigger("change");
            $('#cleaning_guestID').val(id)

        })
    }

    AddCleaningToGuest = () => {
        const guest_id = $('#cleaning_guestID').val(),
            itemstr = $('#cleaning_item').val(),
            price = $('#cleaning_price').val(),
            price_type = $('#cleaning_price_type').val(),
            qty = $('#qty').val()
        if (itemstr.includes('_')) {
            var item = itemstr.replace(/_+/g, ' ')
        } else {
            var item = itemstr
        }
        axios.post('/cleaning-guest', { guest_id, item, price, price_type, qty }).then((ok) => {
            if (ok.data === 'done') {
                this._getGuests();
                window.toastr['success']('تم اضافە الطلب بنجاح')
            } else {
                if (ok.data != 'err') {
                    window.toastr['error']('لیس لدیك کمیة کافیة لهذا الطلب، یرجی اضافة کمیة !')
                } else {
                    window.toastr['error']('خطا')
                }
            }

        })

    }

    payment = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، ادفع الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/payment', { id }).then((ok) => {
                    if (ok.data != 'err') {
                        swal.fire({
                            title: 'تم الدفع!',
                            text: 'تم الدفع الفاتورة بنجاح.',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1400
                        })
                    }
                    window.location.reload();
                })
            }
        })
    }

    Xpayment = (id) => {
        swal.mixin({
            input: 'number',
            confirmButtonText: 'التالي &larr;',
            showCancelButton: true,
            cancelButtonText: 'اغلاق',
            progressSteps: ['١', '٢']
        }).queue([
            {
                title: 'اکتب عدد اللیالي',
                text: 'کم اللیلة بقی فی هذا الغرفة‌؟'
            },
        ]).then((result) => {
            if (result.value) {
                const dayNum = result.value
                swal.fire({
                    title: 'هل أنت متأكد؟',
                    text: "لن تتمكن من التراجع عن هذا!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'اغلاق',
                    confirmButtonText: 'نعم ، ادفع الآن!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('/Xpayment', { id, dayNum }).then((ok) => {
                            if (ok.data != 'NAN' && ok.data != 'Max') {
                                swal.fire({
                                    title: 'تم الدفع!',
                                    text: 'تم الدفع الفاتورة بنجاح.',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400
                                })
                            } else {
                                if (ok.data != 'Max') {
                                    swal.fire({
                                        title: 'حدث خطا!',
                                        text: 'عدد اللیالی اخترتها اکثر من عدد المختارة من قبل !',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        showCancelButton: true,
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: 'حسنا',
                                    })
                                }else{
                                    swal.fire({
                                        title: 'حدث خطا!',
                                        text: 'یرجی انت تکتب علی الاقل واحد.',
                                        icon: 'error',
                                        showConfirmButton: false,
                                        showCancelButton: true,
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: 'حسنا',
                                    })
                                }
                               
                            }
                        })
                    }
                })

            }
        })
    }

    prnt = (print) => {
        $('.modal-backdrop').remove();
        $(".page-content").html($(print).html());
        window.print();
        $(".page-content").html("");
    }

    prntg = (print) => {
        $('.modal-backdrop').remove();
        $(".page-content").html($(print).html());
        window.print();
        $(".page-content").html("");
    }

    _getGuests = () => {
        let id = window.location.href.split('?id=')[1]
        axios.post('/guest-now', { id }).then((ok) => {
            if (ok.data.length != 0) {
                let guests = []
                for (var i = 0; i < ok.data.length; i++) {
                    const name = ok.data[i].fullName,
                        fullname = [],
                        gender = [],
                        address = [],
                        nationality = [],
                        id = ok.data[i].id,
                        personINV = []

                    if (name.includes(',')) {
                        for (var j = 0; j < name.split(',').length; j++) {
                            fullname.push(<div dangerouslySetInnerHTML={{ __html: `${name.split(',')[j]}<br/>` }} />)
                            gender.push(<div dangerouslySetInnerHTML={{ __html: `${ok.data[i].gender.split(',')[j]}<br/>` }} />)
                            address.push(<div dangerouslySetInnerHTML={{ __html: `${ok.data[i].address.split(',')[j]}<br/>` }} />)
                            nationality.push(<div dangerouslySetInnerHTML={{ __html: `${ok.data[i].nationality.split(',')[j]}<br/>` }} />)
                            personINV.push(
                                <>
                                    <div>
                                        الاسم: {name.split(',')[j]} <br />
                                النوع: {ok.data[i].gender.split(',')[j]} <br />
                                عنوان السکن: {ok.data[i].gender.split(',')[j]} <br />
                                الجنسیة: {ok.data[i].nationality.split(',')[j]} <br />
                                    </div>
                                    <br />
                                </>
                            )

                        }
                    } else {
                        fullname.push(ok.data[i].fullName)
                        gender.push(ok.data[i].gender)
                        address.push(ok.data[i].address)
                        nationality.push(ok.data[i].nationality)
                        personINV.push(
                            <div>
                                الاسم: {name} <br />
                            النوع: {ok.data[i].gender} <br />
                            عنوان السکن: {ok.data[i].address} <br />
                            الجنسیة: {ok.data[i].nationality} <br />
                            </div>
                        )
                    }

                    if (ok.data[i].ispaid === '0') {
                        var ispaid = "غیر مدفوع"
                    } else {

                        var ispaid = "تم الدفع"
                    }

                    const attach = []
                    for (var j = 0; j < ok.data[i].attachment.split(',').length; j++) {
                        attach.push(<div className="col-md-6"><img className="img-fluid" src={ok.data[i].attachment.split(',')[j]} /></div>)
                    }

                    guests.push(
                        <tbody id="myTable" className="geustnow">
                            <tr>
                                <td id="idtbl">#{ok.data[i].id}</td>
                                <td>{fullname}</td>
                                <td>{gender}</td>
                                <td>{nationality}</td>
                                <td>{address}</td>
                                <td>{ok.data[i].isgroup}</td>
                                <td>{ok.data[i].phone}</td>
                                <td>{ok.data[i].roomNo}</td>
                                <td style={{ direction: 'ltr' }}>{new Date(ok.data[i].coming).toLocaleString().toString()} </td>
                                <td style={{ direction: 'ltr' }}>{new Date(ok.data[i].going).toLocaleString().toString()} </td>


                                <td>{ok.data[i].cost}</td>
                                <td>{ispaid}</td>


                                <td className="action">
                                    <button className="btn btn-primary btn-sm" type="button" data-toggle="modal" data-target={`.expandaction-${id}`}>
                                        خیارات اخری
                                    </button>
                                    <br />
                                    <button className="btn btn-danger btn-sm xpay" type="button" onClick={() => { this.Xpayment(id) }}>
                                        دفع مسبقا
                                    </button>

                                    <div className={`modal fade  bs-example-modal-center expandaction-${id}`} tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-body">

                                                    <ul className="nav nav-pills nav-justified">
                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" className="nav-link cafx" data-toggle="modal" data-target={`#idcaf-${id}`}>
                                                                <i className="ri-file-list-3-line font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block">  فتح قائمة كافيتريا</span>
                                                            </a>
                                                            <div className="modal fade" id={`idcaf-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" >قائمة كافيتريا</h5>
                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true">×</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <div dangerouslySetInnerHTML={{ __html: ok.data[i].cafeList }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" className="nav-link cleax" data-toggle="modal" data-target={`#idcle-${id}`}>
                                                                <i className="ri-file-list-3-line font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block">  فتح قائمة مواد التنظیفات</span>
                                                            </a>
                                                            <div className="modal fade" id={`idcle-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" >قائمة مواد التنظیفات</h5>
                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true">×</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <div dangerouslySetInnerHTML={{ __html: ok.data[i].cleanList }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" className="nav-link servx" data-toggle="modal" data-target={`#idserv-${id}`}>
                                                                <i className="ri-file-list-3-line font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block"> فتح قائمە خدمات</span>
                                                            </a>
                                                            <div className="modal fade" id={`idserv-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" >  قائمە خدمات </h5>
                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true">×</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <div dangerouslySetInnerHTML={{ __html: ok.data[i].serviceList }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </li>

                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" className="nav-link attax" data-toggle="modal" data-target={`#id-${id}`}>
                                                                <i className="ri-file-list-3-line font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block"> فتح مستمسكات</span>
                                                            </a>
                                                            <div className="modal fade bd-example-modal-lg" id={`id-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" >  مستمسكات  </h5>
                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true">×</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="modal-body" id={"guest-atta-" + id}>
                                                                            <div className="row">
                                                                                {attach}
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer btn-serv">
                                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                                            <button type="button" className="btn btn-primary" onClick={() => { this.prntg("#guest-atta-" + id) }}>طباعە</button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <br />
                                                    <ul className="nav nav-pills nav-justified">
                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" data-toggle="tab" aria-expanded="true" className="nav-link cafe " data-toggle="modal" data-target={`.caffe-${id}`} onClick={() => { this.GetCaffe(id) }}>
                                                                <i className="ri-restaurant-2-fill font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block">اضافة اكلات</span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" data-toggle="tab" aria-expanded="false" className="nav-link service" data-toggle="modal" data-target={`.service-${id}`} onClick={() => { this.GetService(id) }}>
                                                                <i className="ri-truck-fill font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block"> اضافة خدمة</span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" data-toggle="tab" aria-expanded="false" className="nav-link cleaner" data-toggle="modal" data-target={`.cleaning-${id}`} onClick={() => { this.GetCleaning(id) }}>
                                                                <i className="ri-recycle-fill font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block">  اضافة التنظیف</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <br />
                                                    <ul className="nav nav-pills nav-justified">
                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" data-toggle="tab" aria-expanded="true" className="nav-link checkout" data-toggle="modal" data-target={`.prnt-${id}`}>
                                                                <i className="ri-file-list-3-fill font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block"> کشف الحساب</span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a href="javascript: void(0);" data-toggle="tab" aria-expanded="false" className="nav-link paynow" onClick={() => { this.payment(id) }}>
                                                                <i className="ri-check-line font-size-20" />
                                                                <span className="mt-2 d-none d-sm-block">دفع الحساب </span>
                                                            </a>
                                                        </li>
                                                    </ul>

                                                    <div className={`modal fade bd-example-modal-lg caffe-${id}`} tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" >اضافة اكلات </h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">

                                                                    <div className="form-group row">
                                                                        <label className="col-md-2 col-form-label">قائمة الاكلات</label>
                                                                        <div className="col-md-10">
                                                                            <select className="form-control" id="caffe">
                                                                            </select>
                                                                            <br />
                                                                            <p class="card-title-desc"> سعر: <code id="show_price_caffe"></code> </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-md-2 col-form-label" htmlFor="full name">عدد</label>
                                                                        <div className="col-md-10">
                                                                            <input type="number" className="form-control" id="qty" defaultValue={1} />
                                                                        </div>
                                                                    </div>

                                                                    <input type="hidden" id="caffe_guestID" />
                                                                    <input type="hidden" id="caffe_item" />
                                                                    <input type="hidden" id="caffe_price" />
                                                                    <input type="hidden" id="caffe_price_type" />

                                                                    <div className="modal-footer btn-serv">
                                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                                        <button type="button" onClick={() => { this.AddCaffeToGuest() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافة</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`modal fade bd-example-modal-lg cleaning-${id}`} tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" >اضافة اكلات </h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">

                                                                    <div className="form-group row">
                                                                        <label className="col-md-2 col-form-label">قائمة مواد التنظیف</label>
                                                                        <div className="col-md-10">
                                                                            <select className="form-control" id="cleaning">
                                                                            </select>
                                                                            <br />
                                                                            <p class="card-title-desc"> سعر: <code id="show_price_cleaning"></code> </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-group row">
                                                                        <label className="col-md-2 col-form-label" htmlFor="full name">عدد</label>
                                                                        <div className="col-md-10">
                                                                            <input type="number" className="form-control" id="qty" defaultValue={1} />
                                                                        </div>
                                                                    </div>

                                                                    <input type="hidden" id="cleaning_guestID" />
                                                                    <input type="hidden" id="cleaning_item" />
                                                                    <input type="hidden" id="cleaning_price" />
                                                                    <input type="hidden" id="cleaning_price_type" />

                                                                    <div className="modal-footer btn-serv">
                                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                                        <button type="button" onClick={() => { this.AddCleaningToGuest() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافة</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`modal fade bd-example-modal-lg service-${id}`} tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" >اضافة خدمات </h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">

                                                                    <div className="form-group row">
                                                                        <label className="col-md-2 col-form-label">قائمة خدمات</label>
                                                                        <div className="col-md-10">
                                                                            <select className="form-control" id="service">
                                                                            </select>
                                                                            <br />
                                                                            <p class="card-title-desc"> سعر: <code id="show_price"></code> </p>
                                                                        </div>
                                                                    </div>

                                                                    <input type="hidden" id="guestID" />
                                                                    <input type="hidden" id="item" />
                                                                    <input type="hidden" id="price" />

                                                                    <div className="modal-footer btn-serv">
                                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                                        <button type="button" onClick={() => { this.AddServiceToGuest() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافة</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`modal fade bd-example-modal-lg prnt-${id}`} tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" > کشف الحساب </h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="container" id={`guest-inv-${id}`}>
                                                                        <div className="card">
                                                                            <div className="card-header">
                                                                                فاتورة/
                                                                                <strong> {ok.data[i].id}</strong>
                                                                                <span className="float-right"> <strong>حالة:</strong> {ispaid}</span>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row mb-4">
                                                                                    <div className="col-sm-5">
                                                                                        <h6 className="mb-3">معلومات المقیم</h6>
                                                                                        {personINV}
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <h6 className="mb-3">معلومات الغرفة</h6>
                                                                                        <div><strong>الغرفة:</strong> {ok.data[i].roomNo}</div>
                                                                                        <br />
                                                                                        <div style={{ direction: 'ltr' }}><strong>تاریخ الادخال</strong>
                                                                                            <br />
                                                                                            {new Date(ok.data[i].coming).toLocaleString().toString()}</div><br />
                                                                                        <div style={{ direction: 'ltr' }}><strong>تاریخ الخروج</strong>
                                                                                            <br />
                                                                                            {new Date(ok.data[i].going).toLocaleString().toString()} </div><br />

                                                                                        <div><strong>هاتف المقیم</strong>
                                                                                            <br />
                                                                                            {ok.data[i].phone} </div>
                                                                                    </div>
                                                                                    <div className="col-sm-2">
                                                                                        <img className="logoInv" src="../../assets/images/authentication-bg.jpg" />
                                                                                        <br /> <br />
                                                                                        <h6 className="mb-3"><strong>Lavender Motel</strong></h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="table-responsive-sm">
                                                                                    <table className="table table-striped">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className="center">#</th>
                                                                                                <th>نوع الفاتورة</th>
                                                                                                <th>تفاصیل</th>
                                                                                            </tr>
                                                                                        </thead>

                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="center">١</td>
                                                                                                <td className="left strong">
                                                                                                    فاتورة کافيتریا
                                                                                                    </td>
                                                                                                <td className="left">
                                                                                                    <div dangerouslySetInnerHTML={{ __html: ok.data[i].cafeList }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="center">٢</td>
                                                                                                <td className="left">فاتورة الخدمات</td>
                                                                                                <td className="left">
                                                                                                    <div dangerouslySetInnerHTML={{ __html: ok.data[i].serviceList }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-lg-12 col-sm-12 ml-auto">
                                                                                        <table className="table table-clear">
                                                                                            <tbody>

                                                                                                <tr>
                                                                                                    <td className="left">
                                                                                                        <strong>عدد اشخاص</strong>
                                                                                                    </td>
                                                                                                    <td className="right"> {ok.data[i].numguest}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="left">
                                                                                                        <strong>عدد اللیالي</strong>
                                                                                                    </td>
                                                                                                    <td className="right"> {ok.data[i].numNight}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="left">
                                                                                                        <strong>اجرة الغرفة </strong>
                                                                                                    </td>
                                                                                                    <td className="right"> {ok.data[i].roomPrice} IQD </td>
                                                                                                </tr>
                                                                                                <tr id="kalfa">
                                                                                                    <td className="left">
                                                                                                        <strong>حساب الاجمالي</strong>
                                                                                                    </td>
                                                                                                    <td className="right">
                                                                                                        <strong>{ok.data[i].cost} IQD</strong>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-footer">
                                                                                <div className="row">
                                                                                    <div className="col-lg-6 col-sm-6 ml-auto">
                                                                                        <p> <strong>عنوان:  </strong>
                                                                                    السليمانية - رابرين - مقابل مطار سليمانية الدولي</p>
                                                                                        <p> <strong> رقم الهاتف:  </strong>
                                                                                     ‎07509473535 - ‎0770 947 3535</p>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-sm-6 ml-auto">
                                                                                        <p><strong>تاریخ السحب الفاتورة</strong> </p>
                                                                                        <p style={{ direction: 'ltr' }}>{new Date().toLocaleString().toString()}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer btn-serv">
                                                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                                        <button type="button" className="btn btn-primary" onClick={() => { this.prnt(`#guest-inv-${id}`) }}>طباعە</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    )
                }
                this.setState({ GuestsList: guests })
            }
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><Link to="/reception">استعلامات</Link></li>
                                        <li className="breadcrumb-item active">مقیمین الموجودين</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" id="htmltable" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light geustnow">
                                                <tr>
                                                    <th>#</th>
                                                    <th>الاسم</th>
                                                    <th>نوع</th>
                                                    <th>جنسية</th>
                                                    <th>سکن الحالي</th>
                                                    <th>المجموعة</th>
                                                    <th>هاتف</th>
                                                    <th>الغرفة</th>
                                                    <th>ادخال</th>
                                                    <th>اخراج</th>
                                                    <th>حساب الاجمالي</th>
                                                    <th>حساب</th>
                                                    <th>الخيارات</th>
                                                </tr>
                                            </thead>
                                            {this.state.GuestsList}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GuestRoom
