import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route } from 'react-router-dom';
import Layout from './layout'
import Login from './login';

ReactDOM.render(
  <BrowserRouter>
    <Route exact path="/dashboard" component={Layout} />
    <Route exact path="/users" component={Layout} />
    <Route exact path="/staff" component={Layout} />
    <Route exact path="/guests" component={Layout} />
    <Route exact path="/room" component={Layout} />
    <Route exact path="/cafee" component={Layout} />
    <Route exact path="/cleaning" component={Layout} />
    <Route exact path="/service" component={Layout} />
    <Route exact path="/reception" component={Layout} />
    <Route exact path="/guest-room" component={Layout} />
    <Route exact path="/notification" component={Layout} />
    <Route exact path="/spending" component={Layout} />
    <Route exact path="/report-cafe" component={Layout} />
    <Route exact path="/report-cafex" component={Layout} />
    <Route exact path="/report-cleaning" component={Layout} />
    <Route exact path="/report-service" component={Layout} />
    <Route exact path="/report-room" component={Layout} />
    <Route exact path="/modify-guest" component={Layout} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/" component={Login} />

  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
