import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'

class Spending extends Component {
    state = {
        SpendingList: [],
        Total: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        this._getSpendings();
        this._spedingByDate();

    }

    _getSpendings = () => {
        axios.post('/get-spending').then((ok) => {
            if (ok.data.length != 0) {
                let spending = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id,
                        attach = []
                    for (var j = 0; j < ok.data[i].attachment.split(',').length; j++) {
                        attach.push(<div className="col-md-6"><img className="img-fluid" src={ok.data[i].attachment.split(',')[j]} /></div>)
                    }

                    spending.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td>{ok.data[i].name}</td>
                            <td>{ok.data[i].note}</td>
                            <td><div className='badge badge-soft-success font-size-12'>{ok.data[i].cost}</div></td>
                            <td>
                                <a href="javascript: void(0);" data-toggle="modal" data-target={`#id-${id}`}> فتح مستمسكات</a>
                                <div className="modal fade bd-example-modal-lg" id={`id-${id}`} tabIndex={-1} role="dialog" aria-labelledby="newService" aria-hidden="true">
                                    <div className="modal-dialog modal-lg" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" >  مستمسكات  </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body" id={"guest-atta-" + id}>
                                                <div className="row">
                                                    {attach}
                                                </div>
                                            </div>
                                            <div className="modal-footer btn-serv">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </td>
                            <td style={{direction: 'ltr'}}>{new Date(ok.data[i].created).toLocaleString().toString()} </td>
                            <td>
                                <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delSpending(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>
                            </td>
                        </tr>
                    )
                }
                this.setState({ SpendingList: spending })
            }
        })
    }

    _spedingByDate = () => {
        axios.post('/spending-by').then((ok) => {
            const total = []
            if (ok.data.totalDay) {
                var day = ok.data.totalDay
            } else {
                var day = 0
            }

            if (ok.data.totalMonth) {
                var month = ok.data.totalMonth
            } else {
                var month = 0
            }

            if (ok.data.totalYear) {
                var year = ok.data.totalYear
            } else {
                var year = 0
            }


            total.push(
                <div className="row">
                    <div className="col-4">
                        <div className="text-center mt-4">
                            <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary font-size-10 mr-1" /> هذا الیوم</p>
                            <h5>{day} IQD</h5>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="text-center mt-4">
                            <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 mr-1" /> هذا الشهر</p>
                            <h5>{month} IQD</h5>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="text-center mt-4">
                            <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 mr-1" /> هذا السنة</p>
                            <h5>{year} IQD</h5>
                        </div>
                    </div>
                </div>
            )
            this.setState({ Total: total })
        })
    }

    uploadPhotoPost = () => {
        $('<input type="file" accept="image/*" multiple>').on('change', async function () {
            for (var i = 0; i < this.files.length; ++i) {
                var formData = new FormData();
                formData.append('file', this.files[i])
                await axios.post('/uploadImg', formData).then((ok) => {
                    if (ok.data != 'err') {
                        window.toastr['success']('تم الرفع بنجاح !')
                        $('#imgbox').append(`<img class="viewIMGThumb" src="../../../uploads/${ok.data}" height="100px" width="100px" style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;"/> `)
                    } else {
                        window.toastr['error']('يسمح فقط للصورة بالتحميل!')
                    }
                    $(`.viewIMGThumb`).on('click', function () {
                        $(this).remove();
                    })
                })
            }
        }).click();
    }

    AddSpending = () => {
        const name = $('#name').val(),
            note = $('#note').val(),
            cost = $('#cost').val(),
            datex = $('#datex').val(),
            srcs = $(`.viewIMGThumb`).get().map((el) => { return el.src.split('/uploads/')[1] })
        if (srcs.length != 0) {
            var attachment = srcs.toString()
        } else {
            var attachment = 'none'
        }

        if (name.length != 0 && note.length != 0 && datex.length != 0) {
            axios.post('/add-spending', { name, note, cost, datex, attachment }).then((ok) => {
                if (ok.data != 'done') {
                    window.toastr['error']('حدث خطا، یرجی محاول مرة اخری')
                } else {
                    this._getSpendings();
                    $('#name').val()
                    $('#note').val('')
                    $('#cost').val('')
                    $('#datex').val('')
                    $(`.viewIMGThumb`).remove();
                    window.toastr['success']('تم تسجيل بنجاح')
                }
            })
        } else {
            window.toastr['error']('من فضلك املأ الاستمارة')
        }
    }

    delSpending = (id) => {
        swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'اغلاق',
            confirmButtonText: 'نعم ، امسح الآن!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/del-spending?id=' + id).then((ok) => {
                    if (ok.data != 'err') {
                        this._getSpendings();
                        window.toastr['success']('تم المسح بنجاح')
                    } else {
                        window.toastr['success']('تم المسح بنجاح')
                        setTimeout(() => {
                            window.location.reload();
                        }, 400);
                    }
                })
            }
        })
    }


    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <button type="button" className="mb-0 btn btn-primary" data-toggle="modal" data-target="#addspending">اضافة خدمة جدیدة </button>

                                <div className="modal fade" id='addspending' tabIndex={-1} role="dialog" aria-labelledby="addspending" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="newSpending">اضافة مصرف جدیدة </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="form-group">
                                                    <label htmlFor="full name"> اسم الشخص</label>
                                                    <input type="text" className="form-control" id="name" placeholder="اسم ..." required />
                                                </div>

                                                <div className="form-group row">
                                                    <label htmlFor="note">ملاحضة</label>
                                                    <textarea class="form-control" rows="3" type="note" id="note"></textarea>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="cost">مبلغ</label>
                                                    <input type="number" className="form-control" id="cost" placeholder="00.00 IQD" required />
                                                </div>

                                                <div className="form-group row">
                                                    <label htmlFor="datetime-local-input" className="col-2 col-form-label">تاریخ</label>
                                                    <div className="col-10">
                                                        <input className="form-control" type="datetime-local" id="datex" required />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label htmlFor="price" className="col-2 col-form-label">مرفق</label>
                                                    <div className="col-10">
                                                        <div className="form-control up" onClick={() => { this.uploadPhotoPost() }}>
                                                            <i className="ri-upload-cloud-2-fill font-size-18"></i>
                                                        </div>
                                                        <br />
                                                        <div align="center" id="imgbox"></div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">اغلاق</button>
                                                <button type="button" onClick={() => { this.AddSpending() }} id="submit" className="btn btn-primary" data-dismiss='modal' >اضافە</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">ادارة الخدمات</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-xl-9">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>اسم الشخص</th>
                                                    <th>ملاحضة</th>
                                                    <th>مبلغ</th>
                                                    <th>مرفق</th>
                                                    <th>تاریخ</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">
                                                {this.state.SpendingList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3">
                            <div className="card">
                                <div className="card-body" style={{ position: 'relative' }}>
                                    <h4 className="card-title mb-4">مصروفات</h4>
                                    <div className="row">
                                        <div className="col-2"></div>
                                        <div className="col-8"><img src="../../assets/images/expenses.png" className="spending" /></div>
                                        <div className="col-2"></div>
                                    </div>
                                    {this.state.Total}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Spending
