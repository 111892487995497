import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2'
class Notification extends Component {
    state = {
        NotificationList: []
    }

    componentDidUpdate() {
        $("#myInput").on("keyup", function () {
            var value = $(this).val().toLowerCase();
            $(".myTable tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });
    }

    componentDidMount() {
        this._getNotifications();
    }

    _getNotifications = () => {
        axios.post('/get-Notification').then((ok) => {
            if (ok.data.length != 0) {
                let notificationlist = []
                for (var i = 0; i < ok.data.length; i++) {
                    const id = ok.data[i].id
                    notificationlist.push(
                        <tr>
                            <td id="idtbl">#{ok.data[i].id}</td>
                            <td><div className='badge badge-soft-success font-size-12'>{ok.data[i].item}</div></td>
                            <td>{ok.data[i].type}</td>
                            <td style={{direction: 'ltr'}}>{new Date(ok.data[i].created).toLocaleString().toString()} </td>
                            <td>
                                <a href="javascript:void(0);" className="text-danger" onClick={() => { this.delNotification(id) }} > <i className="mdi mdi-trash-can font-size-18" /></a>
                            </td>
                        </tr>
                    )
                }
                this.setState({ NotificationList: notificationlist })
            }
        })
    }

    delNotification = (id) => {
        axios.post('/del-Notification?id=' + id).then((ok) => {
            if (ok.data != 'err') {
                this._getNotifications();
                window.toastr['success']('تم المسح')
            } else {
                window.toastr['success']('تم المسح')
                setTimeout(() => {
                    window.location.reload();
                }, 400);
            }
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">الوحة التحکم</li>
                                        <li className="breadcrumb-item active">ادارة الاشعارات</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input id="myInput" type="text" className="form-control" placeholder="بحث ،،،" />
                                                <span className="ri-search-line" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-centered datatable dt-responsive nowrap" data-page-length={5} style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th> نوع</th>
                                                    <th> تفاصیل</th>
                                                    <th>تاریخ</th>
                                                    <th style={{ width: '120px' }}>خیارات</th>
                                                </tr>
                                            </thead>
                                            <tbody className="myTable">
                                                {this.state.NotificationList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Notification
