import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

class Login extends Component {
    state = {
        readUser: []
    }

    componentDidMount() {
        axios.post('/readUser').then((ok) => {
            if (ok.data != 'err') {
                this.props.history.push('/dashboard')
            }
        })
    }

    login = () => {
        const username = $('#username').val(),
            password = $('#password').val();

        axios.post('/login', { username, password }).then((ok) => {
            if (ok.data != 'err') {
                window.toastr['success']('تم التسجیل الدخول ')
                setTimeout(() => {
                    this.props.history.push('/dashboard')
                }, 300);
            } else {
                window.toastr['error']('اسم المستخدم أو كلمة المرور غير صحيحة')
            }
        })

    }

    render() {
        return (
            <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col-lg-4">
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9">
                                        <div>
                                            <div className="text-center">
                                                <h4 className="font-size-18 mt-4">مرحبا بعودتك !</h4>
                                                <p className="text-muted">قم بتسجيل الدخول للمتابعة</p>
                                            </div>
                                            <div className="p-2 mt-5">
                                                <div className="form-group auth-form-group-custom mb-4">
                                                    <i className="ri-user-2-line auti-custom-input-icon" />
                                                    <label htmlFor="username">اسم المستخدم</label>
                                                    <input type="text" className="form-control" id="username" placeholder="اکتب اسم المستخدم" />
                                                </div>
                                                <div className="form-group auth-form-group-custom mb-4">
                                                    <i className="ri-lock-2-line auti-custom-input-icon" />
                                                    <label htmlFor="userpassword">کلمة مرور</label>
                                                    <input type="password" className="form-control" id="password" placeholder="اکتب کلمە مرور" />
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit" onClick={() => { this.login() }}>تسجيل الدخول</button>
                                                </div>
                                            </div>
                                            <div className="mt-5 text-center">
                                                <p id="copyr8">© 2021 Lavender Motel. Crafted with <i className="mdi mdi-heart text-danger" /> by <a href="https://www.facebook.com/darezhar/" target="_blank">Darezhar</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="authentication-bg">
                            <div className="bg-overlay" />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Login
