import React, { Component } from 'react';
import axios from 'axios';

class Dashboard extends Component {

    state = {
        RoomCost: [],
        CafeCost: [],
        CleaningCost: [],
        ServiceCost: [],
        Income: []
    }

    componentDidMount() {
        this._getDashboard();
    }

    _getDashboard = () => {
        axios.post('/income').then((ok) => {
            const cost = []
            if (ok.data.totalDay) {
                var day = ok.data.totalDay
            } else {
                var day = 0
            }

            if (ok.data.totalMonth) {
                var month = ok.data.totalMonth
            } else {
                var month = 0
            }

            if (ok.data.totalYear) {
                var year = ok.data.totalYear
            } else {
                var year = 0
            }
            if (ok.data.totalYear) {
                var total = ok.data.totalYear
            } else {
                var total = 0
            }

            cost.push(
                <div className="row">
                    <div className="col-12">
                        <h4> الفوائد</h4>
                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الیوم</p>
                                        <h4 className="mb-0">IQD {day}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-money-dollar-circle-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  دخل </span> </span>
                                </div>
                            </div>
                        </div>


                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الشهر</p>
                                        <h4 className="mb-0">IQD {month}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-money-dollar-circle-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  دخل </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا السنة</p>
                                        <h4 className="mb-0">IQD {year}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-money-dollar-circle-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  دخل </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">اجمالی </p>
                                        <h4 className="mb-0">IQD {total}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-money-dollar-circle-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  دخل </span> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
            this.setState({ Income: cost })
        })

        axios.post('/room-invo-cost').then((ok) => {
            const cost = []
            if (ok.data.totalDay) {
                var day = ok.data.totalDay
            } else {
                var day = 0
            }

            if (ok.data.totalMonth) {
                var month = ok.data.totalMonth
            } else {
                var month = 0
            }

            if (ok.data.totalYear) {
                var year = ok.data.totalYear
            } else {
                var year = 0
            }
            if (ok.data.totalYear) {
                var total = ok.data.totalYear
            } else {
                var total = 0
            }

            cost.push(
                <div className="row">
                    <div className="col-12">
                        <h4> الاحصائیة الغرف</h4>
                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الیوم</p>
                                        <h4 className="mb-0">IQD {day}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-hotel-bed-fill font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  الغرف </span> </span>
                                </div>
                            </div>
                        </div>


                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الشهر</p>
                                        <h4 className="mb-0">IQD {month}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-hotel-bed-fill font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  الغرف </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا السنة</p>
                                        <h4 className="mb-0">IQD {year}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-hotel-bed-fill font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  الغرف </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">اجمالی </p>
                                        <h4 className="mb-0">IQD {total}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-hotel-bed-fill font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  الغرف </span> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
            this.setState({ RoomCost: cost })
        })


        axios.post('/cafe-invo-cost').then((ok) => {
            const cost = []
            if (ok.data.totalDay) {
                var day = ok.data.totalDay
            } else {
                var day = 0
            }

            if (ok.data.totalMonth) {
                var month = ok.data.totalMonth
            } else {
                var month = 0
            }

            if (ok.data.totalYear) {
                var year = ok.data.totalYear
            } else {
                var year = 0
            }
            if (ok.data.totalYear) {
                var total = ok.data.totalYear
            } else {
                var total = 0
            }

            cost.push(
                <div className="row">
                    <div className="col-12">
                        <h4>الاحصائیة کافیتریا</h4>
                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الیوم</p>
                                        <h4 className="mb-0">IQD {day}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-restaurant-2-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  کافیتریا </span> </span>
                                </div>
                            </div>
                        </div>


                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الشهر</p>
                                        <h4 className="mb-0">IQD {month}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-restaurant-2-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  کافیتریا </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا السنة</p>
                                        <h4 className="mb-0">IQD {year}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-restaurant-2-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  کافیتریا </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">اجمالی </p>
                                        <h4 className="mb-0">IQD {total}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-restaurant-2-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  کافیتریا </span> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
            this.setState({ CafeCost: cost })
        })

        axios.post('/cleaning-invo-cost').then((ok) => {
            const cost = []
            if (ok.data.totalDay) {
                var day = ok.data.totalDay
            } else {
                var day = 0
            }

            if (ok.data.totalMonth) {
                var month = ok.data.totalMonth
            } else {
                var month = 0
            }

            if (ok.data.totalYear) {
                var year = ok.data.totalYear
            } else {
                var year = 0
            }
            if (ok.data.totalYear) {
                var total = ok.data.totalYear
            } else {
                var total = 0
            }

            cost.push(
                <div className="row">
                    <div className="col-12">
                        <h4>الاحصائیة مواد التنظیف</h4>
                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الیوم</p>
                                        <h4 className="mb-0">IQD {day}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-paint-brush-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  مواد التنظیف </span> </span>
                                </div>
                            </div>
                        </div>


                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الشهر</p>
                                        <h4 className="mb-0">IQD {month}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-paint-brush-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  مواد التنظیف </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا السنة</p>
                                        <h4 className="mb-0">IQD {year}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-paint-brush-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11"> مواد التنظیف </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">اجمالی </p>
                                        <h4 className="mb-0">IQD {total}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-paint-brush-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11"> مواد التنظیف </span> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
            this.setState({ CleaningCost: cost })
        })


        axios.post('/service-invo-cost').then((ok) => {
            const cost = []
            if (ok.data.totalDay) {
                var day = ok.data.totalDay
            } else {
                var day = 0
            }

            if (ok.data.totalMonth) {
                var month = ok.data.totalMonth
            } else {
                var month = 0
            }

            if (ok.data.totalYear) {
                var year = ok.data.totalYear
            } else {
                var year = 0
            }
            if (ok.data.totalYear) {
                var total = ok.data.totalYear
            } else {
                var total = 0
            }

            cost.push(
                <div className="row">
                     <div className="col-12">
                        <h4>الاحصائیة خدمات</h4>
                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الیوم</p>
                                        <h4 className="mb-0">IQD {day}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-luggage-cart-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">  خدمات </span> </span>
                                </div>
                            </div>
                        </div>


                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا الشهر</p>
                                        <h4 className="mb-0">IQD {month}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-luggage-cart-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">   خدمات </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">هذا السنة</p>
                                        <h4 className="mb-0">IQD {year}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-luggage-cart-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">   خدمات </span> </span>
                                </div>
                            </div>
                        </div>

                    </div>
                     <div className="col-xs-2 col-lg-3 col-md-6 col-sm-12" >
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="media-body overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">اجمالی </p>
                                        <h4 className="mb-0">IQD {total}</h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-luggage-cart-line font-size-35" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                    <span className="text-muted ">نوع الاحصائیة: &nbsp; <span className="badge badge-soft-info font-size-11">   خدمات </span> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
            this.setState({ ServiceCost: cost })

        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0"></h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">احصائيات</a></li>
                                        <li className="breadcrumb-item active">الرئيسية</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.Income}
                    <br /> <br />
                    {this.state.RoomCost}
                    <br /> <br />
                    {this.state.CafeCost}
                    <br /> <br />
                    {this.state.CleaningCost}
                    <br /> <br />
                    {this.state.ServiceCost}

                </div>
            </div>
        )
    }
}

export default Dashboard
